// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserStatusV1 = exports.DayOfWeekV1 = exports.CountryV1 = exports.EcommCompanyRoleV1 = exports.SubscriptionFeaturesV1 = exports.SubscriptionBillingFrequencyV1 = exports.SubscriptionV1 = exports.PriceTypeV1 = exports.LanguageV1 = exports.CompanySizeV1 = exports.StatusGenericV1 = exports.UserTypeV1 = exports.ExternalAPIIntegrationsV1 = exports.TruckloadTypeV1 = exports.RangeUnitOfMeasureV1 = exports.CurrencyV1 = void 0;
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var RangeUnitOfMeasureV1;
(function (RangeUnitOfMeasureV1) {
    RangeUnitOfMeasureV1["mi"] = "mi";
    RangeUnitOfMeasureV1["km"] = "km";
})(RangeUnitOfMeasureV1 || (exports.RangeUnitOfMeasureV1 = RangeUnitOfMeasureV1 = {}));
var TruckloadTypeV1;
(function (TruckloadTypeV1) {
    TruckloadTypeV1["FTL"] = "FTL";
    TruckloadTypeV1["LTL"] = "LTL";
})(TruckloadTypeV1 || (exports.TruckloadTypeV1 = TruckloadTypeV1 = {}));
var ExternalAPIIntegrationsV1;
(function (ExternalAPIIntegrationsV1) {
    ExternalAPIIntegrationsV1["MORNEAU"] = "MORNEAU";
    ExternalAPIIntegrationsV1["CSA"] = "CSA";
})(ExternalAPIIntegrationsV1 || (exports.ExternalAPIIntegrationsV1 = ExternalAPIIntegrationsV1 = {}));
var UserTypeV1;
(function (UserTypeV1) {
    UserTypeV1["CARRIER"] = "CARRIER";
    UserTypeV1["SHIPPER"] = "SHIPPER";
    UserTypeV1["TRUXWEB"] = "TRUXWEB";
    UserTypeV1["EVIL_SUPER_ADMIN"] = "EVIL-SUPER-ADMIN";
})(UserTypeV1 || (exports.UserTypeV1 = UserTypeV1 = {}));
var StatusGenericV1;
(function (StatusGenericV1) {
    StatusGenericV1["ACTIVE"] = "ACTIVE";
    StatusGenericV1["INACTIVE"] = "INACTIVE";
    StatusGenericV1["ARCHIVED"] = "ARCHIVED";
    StatusGenericV1["PENDING"] = "PENDING";
    StatusGenericV1["REVISE"] = "REVISE";
    StatusGenericV1["REGISTERED"] = "REGISTERED";
    StatusGenericV1["BANNED"] = "BANNED";
})(StatusGenericV1 || (exports.StatusGenericV1 = StatusGenericV1 = {}));
var CompanySizeV1;
(function (CompanySizeV1) {
    CompanySizeV1[1] = "1";
    CompanySizeV1[11] = "11";
    CompanySizeV1[51] = "51";
    CompanySizeV1[101] = "101";
    CompanySizeV1[1000] = "1000";
    CompanySizeV1[1001] = "1001";
})(CompanySizeV1 || (exports.CompanySizeV1 = CompanySizeV1 = {}));
var LanguageV1;
(function (LanguageV1) {
    LanguageV1["EN_CA"] = "EN-CA";
    LanguageV1["FR_CA"] = "FR-CA";
})(LanguageV1 || (exports.LanguageV1 = LanguageV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
var SubscriptionV1;
(function (SubscriptionV1) {
    SubscriptionV1["FREE"] = "FREE";
    SubscriptionV1["LEVEL_ONE"] = "LEVEL_ONE";
})(SubscriptionV1 || (exports.SubscriptionV1 = SubscriptionV1 = {}));
var SubscriptionBillingFrequencyV1;
(function (SubscriptionBillingFrequencyV1) {
    SubscriptionBillingFrequencyV1["MONTHLY"] = "MONTHLY";
    SubscriptionBillingFrequencyV1["QUARTERLY"] = "QUARTERLY";
    SubscriptionBillingFrequencyV1["BIANNUALLY"] = "BIANNUALLY";
    SubscriptionBillingFrequencyV1["ANNUALLY"] = "ANNUALLY";
})(SubscriptionBillingFrequencyV1 || (exports.SubscriptionBillingFrequencyV1 = SubscriptionBillingFrequencyV1 = {}));
var SubscriptionFeaturesV1;
(function (SubscriptionFeaturesV1) {
    SubscriptionFeaturesV1["SAVE_LOAD_FROM_SEARCH"] = "SAVE_LOAD_FROM_SEARCH";
    SubscriptionFeaturesV1["VIEW_INSTANT_PRICING"] = "VIEW_INSTANT_PRICING";
})(SubscriptionFeaturesV1 || (exports.SubscriptionFeaturesV1 = SubscriptionFeaturesV1 = {}));
var EcommCompanyRoleV1;
(function (EcommCompanyRoleV1) {
    EcommCompanyRoleV1["SHIPPER_BROKER"] = "SHIPPER_BROKER";
    EcommCompanyRoleV1["INTERLINING_CARRIER"] = "INTERLINING_CARRIER";
    EcommCompanyRoleV1["PREFERRED_CARRIER"] = "PREFERRED_CARRIER";
})(EcommCompanyRoleV1 || (exports.EcommCompanyRoleV1 = EcommCompanyRoleV1 = {}));
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var DayOfWeekV1;
(function (DayOfWeekV1) {
    DayOfWeekV1["Monday"] = "Monday";
    DayOfWeekV1["Tuesday"] = "Tuesday";
    DayOfWeekV1["Wednesday"] = "Wednesday";
    DayOfWeekV1["Thursday"] = "Thursday";
    DayOfWeekV1["Friday"] = "Friday";
    DayOfWeekV1["Saturday"] = "Saturday";
    DayOfWeekV1["Sunday"] = "Sunday";
})(DayOfWeekV1 || (exports.DayOfWeekV1 = DayOfWeekV1 = {}));
var UserStatusV1;
(function (UserStatusV1) {
    UserStatusV1["Active"] = "ACTIVE";
    UserStatusV1["Inactive"] = "INACTIVE";
    UserStatusV1["Banned"] = "BANNED";
    UserStatusV1["Pending"] = "PENDING";
    UserStatusV1["Review"] = "REVIEW";
    UserStatusV1["Registered"] = "REGISTERED";
})(UserStatusV1 || (exports.UserStatusV1 = UserStatusV1 = {}));
