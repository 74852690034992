// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierPricingActionV1 = void 0;
var CarrierPricingActionV1;
(function (CarrierPricingActionV1) {
    CarrierPricingActionV1["EXCLUDE"] = "EXCLUDE";
    CarrierPricingActionV1["SPOT_QUOTE"] = "SPOT_QUOTE";
    CarrierPricingActionV1["CUBING"] = "CUBING";
    CarrierPricingActionV1["STACKABLE_ADJ"] = "STACKABLE_ADJ";
    CarrierPricingActionV1["FUEL_ADJ"] = "FUEL_ADJ";
    CarrierPricingActionV1["PALLET_COUNT_ADJ"] = "PALLET_COUNT_ADJ";
})(CarrierPricingActionV1 || (exports.CarrierPricingActionV1 = CarrierPricingActionV1 = {}));
