"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessorialPickerCard = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const utils_1 = require("@truxweb/utils");
const AccessorialPickerCard_styles_1 = require("./AccessorialPickerCard.styles");
const AccessorialPickerCard = ({ accessorialFormControls, accessorials, control, deleteHandler, handlePickerChange, hasError, index, isCompactDisplay, isDisplayOnly, prefix, selectedAccessorial, shouldForceMetadataInput, t, testId, variant, }) => {
    const classes = (0, AccessorialPickerCard_styles_1.useStyles)();
    const doesRequiresMetadataInput = (0, react_1.useMemo)(() => {
        var _a, _b;
        if (!selectedAccessorial)
            return false;
        let shouldInputMetadata = false;
        if (Boolean((_a = selectedAccessorial === null || selectedAccessorial === void 0 ? void 0 : selectedAccessorial.metadata) === null || _a === void 0 ? void 0 : _a.length)) {
            shouldInputMetadata =
                shouldForceMetadataInput ||
                    Boolean((_b = (0, utils_1.getAccessorialOptionalMetadata)(selectedAccessorial)) === null || _b === void 0 ? void 0 : _b.length);
        }
        return shouldInputMetadata;
    }, [selectedAccessorial, shouldForceMetadataInput]);
    const handleBeforeOnChange = (0, react_1.useCallback)((event, index) => {
        handlePickerChange(event, index);
    }, [handlePickerChange]);
    let containerSize = 12;
    if (doesRequiresMetadataInput && selectedAccessorial !== undefined && !isCompactDisplay) {
        containerSize = 6;
    }
    const content = (react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, justifyContent: "center", spacing: isCompactDisplay ? 1 : undefined },
        react_1.default.createElement(ux_1.Grid, { item: true, xs: containerSize },
            react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: `${prefix}.${index}.code`, render: ({ field: { onChange, value } }) => {
                    return (react_1.default.createElement(__1.AccessorialsSelect, { accessorials: Object.values(accessorials), beforeOnChange: handleBeforeOnChange, hasError: hasError, index: index, isDisabled: isDisplayOnly, name: `${prefix}.${index}.code`, onChange: (event) => {
                            if (accessorials[event.target.value]) {
                                accessorialFormControls.update(index, accessorials[event.target.value]);
                                onChange(event);
                            }
                        }, t: t, testId: testId, value: value || '', variant: 'filled' }));
                } })),
        doesRequiresMetadataInput && selectedAccessorial !== undefined && (react_1.default.createElement(__1.AccessorialMetadata, { control: control, index: index, isCompactDisplay: isCompactDisplay, isConfirmation: isDisplayOnly, prefix: prefix, selectedAccessorial: selectedAccessorial, t: t }))));
    if (isDisplayOnly) {
        return react_1.default.createElement(ux_1.LineItem, { className: classes.accessorialLineItem }, content);
    }
    return (react_1.default.createElement(ux_1.Box, { className: classes.wrapper },
        react_1.default.createElement(ux_1.DeletableCard, { handleDelete: deleteHandler, testId: `${testId}-AccessorialPickerCard-${index}`, variant: variant }, content)));
};
exports.AccessorialPickerCard = AccessorialPickerCard;
