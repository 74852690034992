// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinearFeetDimensionV1 = void 0;
var LinearFeetDimensionV1;
(function (LinearFeetDimensionV1) {
    LinearFeetDimensionV1["LENGTH"] = "LENGTH";
    LinearFeetDimensionV1["WIDTH"] = "WIDTH";
})(LinearFeetDimensionV1 || (exports.LinearFeetDimensionV1 = LinearFeetDimensionV1 = {}));
