"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    accessorialLineItem: {
        borderRadius: `6.5px 6.5px`,
        height: 'auto',
        maxWidth: theme.spacing(80),
        minHeight: theme.spacing(9),
        minWidth: theme.spacing(45),
        padding: theme.spacing(2),
    },
    wrapper: {
        '& .MuiChip-deleteIcon': {
            margin: 0,
        },
        margin: '0px',
    },
}));
