"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchAccessorials = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const SearchAccessorials_styles_1 = require("./SearchAccessorials.styles");
const SearchAccessorials = ({ accessorialFormControls, accessorialRecords, availableAccessorials, control, isDisplayOnly, shouldForceMetadataInput, t, variant, }) => {
    const classes = (0, SearchAccessorials_styles_1.useStyles)();
    const [totalAccessorialPickers, setTotalAccessorialPickers] = (0, react_1.useState)(1);
    const [totalAvailableAccessorials, setTotalAvailableAccessorials] = (0, react_1.useState)((availableAccessorials === null || availableAccessorials === void 0 ? void 0 : availableAccessorials.length) || 0);
    const handleDeleteAccessorial = (0, react_1.useCallback)((index) => () => {
        accessorialFormControls.remove(index);
        setTotalAccessorialPickers((prev) => prev - 1);
    }, [accessorialFormControls]);
    const handleAddAccessorial = (0, react_1.useCallback)(() => {
        if (totalAccessorialPickers < totalAvailableAccessorials) {
            accessorialFormControls.append({ code: '' });
            setTotalAccessorialPickers((prev) => prev + 1);
        }
    }, [accessorialFormControls, totalAccessorialPickers, totalAvailableAccessorials]);
    const handlePickerChange = (0, react_1.useCallback)((event, index) => {
        accessorialFormControls.update(index, accessorialRecords[event.target.value]);
    }, [accessorialRecords, accessorialFormControls]);
    //Calculate what accessorial Options to display and which fields to remove
    const [accessorialsToDisplay] = (0, react_1.useMemo)(() => {
        const { fields } = accessorialFormControls;
        const selectedValues = (fields === null || fields === void 0 ? void 0 : fields.length) > 0
            ? fields
                .map((accessorial) => {
                return accessorial.code;
            })
                .filter((accessorial) => accessorial)
            : [];
        const accessorialsToDisplay = (availableAccessorials || [])
            .filter((accessorial) => {
            return (accessorial.visibleToShipper && accessorial.code !== schemas_1.EAccessorialOptionV1.CARGO_INSURANCE);
        })
            .map((accessorial) => {
            return Object.assign(Object.assign({}, accessorial), { disabled: selectedValues.includes(accessorial.code) ? true : false });
        });
        const accessorialLookup = {};
        accessorialsToDisplay.forEach((record) => {
            accessorialLookup[record.code] = record;
        });
        return [accessorialLookup];
    }, [availableAccessorials, accessorialFormControls]);
    (0, react_1.useEffect)(() => {
        setTotalAvailableAccessorials(Object.values(accessorialsToDisplay).length || 0);
    }, [
        accessorialsToDisplay,
        accessorialFormControls,
        availableAccessorials,
        totalAccessorialPickers,
    ]);
    (0, react_1.useEffect)(() => {
        if (!accessorialFormControls.fields.length) {
            handleAddAccessorial();
        }
    }, [accessorialFormControls, handleAddAccessorial]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.Grid, { container: true, direction: isDisplayOnly ? 'column' : 'row', spacing: 2 }, (accessorialFormControls.fields || []).map((field, index) => {
            return (react_1.default.createElement(ux_1.Grid, { item: true, key: index },
                react_1.default.createElement(ux_1.Zoom, { in: !isDisplayOnly },
                    react_1.default.createElement(__1.AccessorialPickerCard, { accessorialFormControls: accessorialFormControls, accessorials: accessorialsToDisplay, control: control, deleteHandler: handleDeleteAccessorial(index), handlePickerChange: handlePickerChange, hasError: false, index: index, isDisplayOnly: isDisplayOnly, prefix: "accessorials", selectedAccessorial: field, shouldForceMetadataInput: shouldForceMetadataInput, t: t, testId: 'SearchAccessorials', variant: variant }))));
        })),
        !isDisplayOnly && (react_1.default.createElement(ux_1.Container, { className: classes.accessorialPicker },
            react_1.default.createElement(ux_1.Box, { mt: 3 },
                react_1.default.createElement(ux_1.Button, { className: classes.button, "data-testid": `SearchAccessorials-Button`, onClick: handleAddAccessorial, startIcon: react_1.default.createElement(ux_1.AddCircleOutlineIcon, null), variant: "text" },
                    react_1.default.createElement(ux_1.Typography, { className: classes.buttonText }, t(`search:addAccessorial`))))))));
};
exports.SearchAccessorials = SearchAccessorials;
