import { useCallback, useEffect, useState } from 'react';
import {
  useErrorHandling,
  usePollResponse,
  useSavedSearchFormData,
  useSearchFormParams,
  useSearchLoadingState,
  useSearchRequestId,
  useShipmentCredits,
} from '../..';
import { bookingSearch } from '../../../actions';
import { convertFormDataToBookingQueryV1 } from '@truxweb/utils';
import { setSearchResultsData } from '../../../stores';
import { type TBookingSearchResponseV1 } from '@truxweb/schemas';
import { useDispatch } from 'react-redux';

export const useSearch = (shouldRefresh: boolean) => {
  const [searchRequestId, setSearchRequestId] = useState<null | number>(null);
  const dispatch = useDispatch();
  const { data: searchFormParams, hasLoaded: haveSearchParamsLoaded } = useSearchFormParams();
  const { data: savedFormData } = useSavedSearchFormData();
  const shipmentCredits = useShipmentCredits(shouldRefresh);

  const searchQuery = savedFormData
    ? convertFormDataToBookingQueryV1(
        savedFormData,
        searchFormParams,
        Boolean(shipmentCredits?.data?.length)
      )
    : null;
  const searchRequest = useSearchRequestId(searchQuery, shipmentCredits?.hasLoaded);

  const errorHandler = useErrorHandling();

  const pollFn = useCallback(async () => {
    if (!searchRequestId) return null;
    return await bookingSearch(searchRequestId);
  }, [searchRequestId]);

  const successFn = useCallback(
    (results: TBookingSearchResponseV1) => {
      setSearchRequestId(null);
      dispatch(setSearchResultsData({ data: results }));
    },
    [dispatch, setSearchRequestId]
  );

  const areResultsLoading = usePollResponse(searchRequestId, {
    errorFn: errorHandler,
    pollFn,
    successFn,
  });

  const { data: searchResults, error } = useSearchLoadingState();
  useEffect(() => {
    if (searchRequest?.hasLoaded) {
      setSearchRequestId(searchRequest.data);
    }
  }, [searchRequest, setSearchRequestId]);

  return {
    areResultsLoading: areResultsLoading || searchRequest.isLoading,
    error,
    haveResultsLoaded: !areResultsLoading && searchRequest?.hasLoaded,
    haveSearchParamsLoaded,
    savedFormData,
    searchQuery,
    searchResults,
  };
};
