// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinearFeetDimensionV1 = exports.CarrierPricingMetricConfigOptionTypeV1 = exports.CarrierPricingMetricV1 = void 0;
var CarrierPricingMetricV1;
(function (CarrierPricingMetricV1) {
    CarrierPricingMetricV1["TOTAL_PALLET_COUNT"] = "TOTAL_PALLET_COUNT";
    CarrierPricingMetricV1["PALLET_WEIGHT"] = "PALLET_WEIGHT";
    CarrierPricingMetricV1["PALLET_WIDTH"] = "PALLET_WIDTH";
    CarrierPricingMetricV1["PALLET_HEIGHT"] = "PALLET_HEIGHT";
    CarrierPricingMetricV1["PALLET_HEIGHT_CLAMP"] = "PALLET_HEIGHT_CLAMP";
    CarrierPricingMetricV1["PALLET_LENGTH"] = "PALLET_LENGTH";
    CarrierPricingMetricV1["LINEAR_FEET"] = "LINEAR_FEET";
    CarrierPricingMetricV1["LINEAR_FEET_CLAMP"] = "LINEAR_FEET_CLAMP";
    CarrierPricingMetricV1["TOTAL_ACTUAL_WEIGHT"] = "TOTAL_ACTUAL_WEIGHT";
    CarrierPricingMetricV1["LABRANCHE_VOLUME"] = "LABRANCHE_VOLUME";
    CarrierPricingMetricV1["MESSAGERS_VOLUME"] = "MESSAGERS_VOLUME";
    CarrierPricingMetricV1["MINIMAX_VOLUME"] = "MINIMAX_VOLUME";
})(CarrierPricingMetricV1 || (exports.CarrierPricingMetricV1 = CarrierPricingMetricV1 = {}));
var CarrierPricingMetricConfigOptionTypeV1;
(function (CarrierPricingMetricConfigOptionTypeV1) {
    CarrierPricingMetricConfigOptionTypeV1["NUMBER"] = "NUMBER";
})(CarrierPricingMetricConfigOptionTypeV1 || (exports.CarrierPricingMetricConfigOptionTypeV1 = CarrierPricingMetricConfigOptionTypeV1 = {}));
var LinearFeetDimensionV1;
(function (LinearFeetDimensionV1) {
    LinearFeetDimensionV1["LENGTH"] = "LENGTH";
    LinearFeetDimensionV1["WIDTH"] = "WIDTH";
})(LinearFeetDimensionV1 || (exports.LinearFeetDimensionV1 = LinearFeetDimensionV1 = {}));
