"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    button: {
        background: '#EDF8FF',
        border: 0,
        borderRadius: '100%',
        cursor: 'pointer',
        height: theme.spacing(3),
        position: 'relative',
        width: theme.spacing(3),
    },
    icon: {
        fill: theme.palette.primary.light,
        marginTop: '-1px',
        width: theme.spacing(2),
    },
}));
