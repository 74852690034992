import { type AppState, baseLoadingState, type ISearchDataState } from '../../stores';

export const selectSavedFormData = (state: AppState): ISearchDataState['savedFormData'] => {
  const searchFormData = state?.search?.savedFormData || {
    ...baseLoadingState,
    shouldForceRefresh: false,
  };

  if (!searchFormData?.data) return searchFormData;
  return {
    ...searchFormData,
    data: {
      ...searchFormData.data,
    },
  };
};
