import {
  addSearchToLocalStorage,
  getLocalizedRoute,
  getMinimumShipmentDate,
  getRecentSeachesByLocationType,
  restoreSearchForm,
  saveBookingQuery,
  saveSearchForm,
} from '../../utils';
import { CircularProgress, Grid } from '@truxweb/ux';
import { clearSearchResults, setSearchFormData } from '../../stores';
import { convertFormDataToBookingQueryV1, transformI18nLocaleToLanguage } from '@truxweb/utils';
import {
  DEFAULT_QUANTITY,
  DEFAULT_TRUCK_SELECTION,
  DEFAULT_TRUCK_TYPE,
  GOOGLE_MAPS_API_KEY,
} from '../../config';
import {
  EAccessorialOptionV1,
  ECarrierTruckTypeV1,
  EPermissionV1,
  ETruckloadTypeV1,
  type TQuoteSearchFormDataV1,
} from '@truxweb/schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAlerts,
  useCompanyLocations,
  useSavedSearchFormData,
  useSearchFormParams,
  useSearchLoad,
  useShipmentCredits,
  useUserData,
} from '../../hooks';
import { createSearchRequest } from '../../actions';
import { SearchInput } from '@truxweb/common-components';
import { ShipperPage } from '../../pageTemplates';
import { TruxiiComingSoon } from '../../components';
import { type TTruxwebPageContentProps } from '../../types';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'search', 'loading', 'select'];

export type TSearchFormSelection = {
  origin: string;
  destination: string;
  additionalStops: string[];
  shpimentDate: string[];
  truckloadType: ETruckloadTypeV1;
  equipment: ECarrierTruckTypeV1;
  shipmentFeatures: EAccessorialOptionV1[];
};

export const SearchPage = ({ pageId }: TTruxwebPageContentProps): JSX.Element => {
  const existingFormData = restoreSearchForm();

  const loadDefinition = useSearchLoad();
  const { t } = useTranslation([...REQUIRED_NAMESPACES, pageId]);
  const dispatch = useDispatch();
  const [shouldCompanyLocationRefetch, setShouldCompanyLocationRefetch] = useState(true);
  const [shouldFormParamsRefetch, setShouldFormParamsRefetch] = useState(true);
  const shipmentCredits = useShipmentCredits(shouldFormParamsRefetch);

  const { addAlert } = useAlerts();
  const { userData } = useUserData();
  const router = useRouter();
  const { locale } = router;

  const { data: searchParams, hasLoaded, isLoading } = useSearchFormParams(shouldFormParamsRefetch);
  const { data: savedFormData } = useSavedSearchFormData();
  const { data: companyLocations, hasLoaded: haveCompanyLocationsLoaded } = useCompanyLocations(
    shouldCompanyLocationRefetch
  );

  const companyLocationData = useMemo(() => {
    if (!haveCompanyLocationsLoaded) return [];
    return companyLocations.map(({ data }) => data);
  }, [companyLocations, haveCompanyLocationsLoaded]);

  // Set map properties
  const handleSubmitSuccess = useCallback(
    (data: TQuoteSearchFormDataV1) => {
      if (!searchParams) {
        addAlert({
          message: t('common:unableToSubmitSearch'),
          severity: 'error',
        });
      }

      saveSearchForm(data);
      addSearchToLocalStorage(data);

      const query = convertFormDataToBookingQueryV1(
        data,
        searchParams,
        Boolean(shipmentCredits.data.length)
      );

      saveBookingQuery(query);
      dispatch(setSearchFormData({ data, shouldForceRefresh: false }));
      dispatch(createSearchRequest(query));

      const resultsRoute = getLocalizedRoute(router.locale, 'results');
      router.push(resultsRoute);

      // Save the search params to local storages
    },
    [searchParams, dispatch, router, addAlert, t, shipmentCredits]
  );

  useEffect(() => {
    if (!savedFormData && existingFormData) {
      dispatch(setSearchFormData({ data: existingFormData, shouldForceRefresh: false }));
    }
  }, [dispatch, existingFormData, savedFormData]);

  useEffect(() => {
    if (shouldCompanyLocationRefetch) {
      setShouldCompanyLocationRefetch(false);
    }
  }, [shouldCompanyLocationRefetch, setShouldCompanyLocationRefetch]);

  useEffect(() => {
    if (shouldFormParamsRefetch) {
      setShouldFormParamsRefetch(false);

      // When we reload the search params, we want to clear
      // out any previous search results
      dispatch(clearSearchResults());
    }
  }, [shouldFormParamsRefetch, setShouldFormParamsRefetch, dispatch]);

  return (
    <ShipperPage pageId={pageId} permissions={[EPermissionV1.VIEW_SEARCH_RESULTS]}>
      {!hasLoaded && (
        <Grid alignItems="center" container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {hasLoaded && (
        <SearchInput
          addAlert={addAlert}
          comingSoon={<TruxiiComingSoon />}
          customCompleteValues={companyLocationData}
          defaultQuantity={DEFAULT_QUANTITY}
          defaultTruckSelection={DEFAULT_TRUCK_SELECTION}
          defaultTruckType={DEFAULT_TRUCK_TYPE}
          existingFormData={{
            ...savedFormData,
            loadDefinition: savedFormData?.loadDefinition || loadDefinition,
          }}
          formParams={searchParams}
          getRecentSeachesByLocationType={getRecentSeachesByLocationType}
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          handleSubmitSuccess={handleSubmitSuccess}
          isLoading={isLoading || shipmentCredits.isLoading}
          language={transformI18nLocaleToLanguage(locale)}
          loadDefinition={loadDefinition}
          minDate={getMinimumShipmentDate}
          t={t}
          userData={userData}
        />
      )}
    </ShipperPage>
  );
};
