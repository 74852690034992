"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchExtendedParams = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const SearchExtendedParams_styles_1 = require("./SearchExtendedParams.styles");
const SearchExtendedParams = ({ accessorialFormControls, accessorials, availableAccessorials, control, equipment, errors, formParams, isExpandedByDefault, isLoading, isSubmitDisabled, setValue, t, values, watch, }) => {
    var _a;
    const accordionStyles = (0, SearchExtendedParams_styles_1.useAccordionStyles)();
    const classes = (0, SearchExtendedParams_styles_1.useStyles)();
    const [isExpanded, setExpanded] = (0, react_1.useState)(isExpandedByDefault);
    const [shipmentType, setShipmentType] = (0, react_1.useState)(null);
    const { append, fields: cargoFields, remove, update, } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'loadDefinition.cargoSpecifications',
    });
    const equipmentChange = watch('equipment');
    const findShipmentType = (0, react_1.useCallback)((equipmentCode) => {
        /* eslint-disable max-nested-callbacks */
        return Object.keys(formParams.shipmentFeaturesEquipment).find((key) => {
            return Object.keys(formParams.shipmentFeaturesEquipment[key]).find((featureKey) => {
                return formParams.shipmentFeaturesEquipment[key][featureKey].find((code) => {
                    return code === equipmentCode;
                });
            });
        });
        /* eslint-enable max-nested-callbacks */
    }, [formParams]);
    (0, react_1.useEffect)(() => {
        if (!shipmentType) {
            setShipmentType(findShipmentType(equipmentChange === null || equipmentChange === void 0 ? void 0 : equipmentChange.code) || null);
        }
    }, [shipmentType, setShipmentType, formParams, equipmentChange, findShipmentType]);
    return (react_1.default.createElement(ux_1.Grid, null,
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Box, { mb: 2, mt: 1 },
                    react_1.default.createElement(ux_1.Collapse, { classes: accordionStyles, in: isExpanded, unmountOnExit: true },
                        react_1.default.createElement(ux_1.Box, { className: classes.optionsWrapper },
                            react_1.default.createElement(ux_1.Box, { className: classes.optionItem },
                                react_1.default.createElement(ux_1.Typography, { className: classes.sectionTitles, variant: "body1" }, t(`search:equipment`)),
                                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: '', name: "equipment", render: ({ field: { onChange, value } }) => {
                                        const handleEquipmentChange = (event) => {
                                            // Need to find shipment type based on equipment selection
                                            //
                                            // NOTE: This assumes that there is never an equipment type
                                            // associated with more than one shipment type, which may not
                                            // always be the case.
                                            //
                                            // If that _does_ occur this will only return the first shipment type
                                            // the equipment belongs to.
                                            //
                                            // To resolve this, the solution will be to introduce a requirement
                                            // to select the shipment type
                                            // Yes, truckloadType is sometime called shipmentType
                                            const truckloadType = findShipmentType(event.code);
                                            setValue('truckloadType', truckloadType);
                                            setShipmentType(truckloadType || null);
                                            onChange(event);
                                        };
                                        return (react_1.default.createElement(__1.SearchEquipmentSelection, { equipment: equipment, hasError: false, name: 'equipment', onChange: handleEquipmentChange, searchEquipment: formParams.searchEquipment, t: t, value: value, variant: 'filled' }));
                                    } })),
                            react_1.default.createElement(ux_1.Box, { className: classes.optionItem },
                                react_1.default.createElement(ux_1.Typography, { className: classes.sectionTitles, variant: "body1" }, t(`search:additionalServices`)),
                                react_1.default.createElement(__1.SearchAccessorials, { accessorialFormControls: accessorialFormControls, accessorialRecords: accessorials || {}, availableAccessorials: availableAccessorials || [], control: control, t: t, variant: "primaryLight" })),
                            react_1.default.createElement(ux_1.Collapse, { in: shipmentType === schemas_1.ETruckloadTypeV1.LTL },
                                react_1.default.createElement(ux_1.Box, { className: classes.optionItem },
                                    react_1.default.createElement(ux_1.Typography, { className: classes.sectionTitles, variant: "body1" }, t(`common:cargo`)),
                                    react_1.default.createElement(ux_1.Box, { mt: 3, pb: 6 },
                                        react_1.default.createElement(__1.LtlCargoSpecifications, { control: control, equipmentCode: equipmentChange === null || equipmentChange === void 0 ? void 0 : equipmentChange.code, errors: ((_a = errors === null || errors === void 0 ? void 0 : errors.loadDefinition) === null || _a === void 0 ? void 0 : _a.cargoSpecifications) || [], fields: cargoFields, formAppend: append, formRemove: remove, formValueName: "loadDefinition.cargoSpecifications", isTemperatureDataRequired: shipmentType === schemas_1.ETruckloadTypeV1.LTL &&
                                                (equipmentChange === null || equipmentChange === void 0 ? void 0 : equipmentChange.code.includes('REEFER')), setValue: setValue, t: t, update: update, values: values, watch: watch }))))))))),
        react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "space-between" },
            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-start", xs: true },
                react_1.default.createElement(ux_1.StandardButton, { onClick: () => {
                        setExpanded(!isExpanded);
                    } }, isExpanded ? (react_1.default.createElement(ux_1.ExpandLessIcon, { className: classes.accordionButton })) : (react_1.default.createElement(ux_1.ExpandMoreIcon, { className: classes.accordionButton })))),
            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", style: { paddingBottom: '24px', paddingRight: '48px' }, xs: true },
                react_1.default.createElement(__1.SaveButton, { color: "primaryLight", isDisabled: isSubmitDisabled || isLoading, t: t }, t('search:search'))))));
};
exports.SearchExtendedParams = SearchExtendedParams;
