"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    itemBox: {
        background: theme.palette.common.white,
        borderRadius: `${theme.spacing(1)}px`,
        height: `${theme.spacing(6)}px`,
    },
}));
