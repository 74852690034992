import { makeRequestToApiGateway } from '../../../apiUtils';
import type { TBookingSearchResponseV1 } from '@truxweb/schemas';

export const bookingSearch = async (messageId: number): Promise<TBookingSearchResponseV1> => {
  return await makeRequestToApiGateway(
    'bookingGetV1Booking',
    undefined,
    {
      'Content-Type': 'application/json',
    },
    undefined,
    { messageId }
  );
};
