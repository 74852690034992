"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralCSPDataGrid = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const quote_utils_1 = require("@truxweb/quote-utils");
const hooks_1 = require("@truxweb/hooks");
const DEFAULT_ROW_HEIGHT = 96;
const DEFAULT_PAGE_SIZE = 10;
const GeneralCSPDataGrid = ({ areColumnsHidden, areFiltersEnabled, availableColumns, columnKeys, containerClassName, currentSort, data, defaultSort, filterMode, gridActions, handleCellClick, handleColumnVisibilityChange, handleExport, handleFilterChange, handleSortingChange, handleToggleAutoRefresh, isActionsOverlayEnabled, isExportEnabled, isHeaderShown, isLazyLoading, isLoading, language, lastLoadedDate, onPageChange, onPageSizeChange, pageSize, paginationMode, renderActionsContent, rowCount, rowHeight, selectedRows, shouldAutoRefresh, shouldForcePageSize, sortingMode, t, }) => {
    const tableRowHeight = rowHeight || DEFAULT_ROW_HEIGHT;
    const dynamicPageSize = (0, hooks_1.useDynamicTableRowCount)({
        constraints: { lg: '1524px', xlg: '1824px' },
        defaultPageSize: pageSize || DEFAULT_PAGE_SIZE,
        isLoading: Boolean(isLoading),
        onPageSizeChange,
        useMediaQuery: ux_1.useMediaQuery,
    });
    pageSize =
        (shouldForcePageSize && Boolean(pageSize) ? pageSize : dynamicPageSize) || DEFAULT_PAGE_SIZE;
    const [displayCols] = (0, react_1.useMemo)(() => {
        const fullColumns = availableColumns
            .filter((col) => Boolean(col))
            .map((col) => {
            return Object.assign(Object.assign({}, col), { description: col === null || col === void 0 ? void 0 : col.headerName });
        });
        const displayCols = fullColumns.map((col) => {
            if (col.disableColumnMenu)
                return col;
            if (!columnKeys) {
                return Object.assign(Object.assign({}, col), { hide: false });
            }
            return Object.assign(Object.assign({}, col), { hide: !columnKeys.includes(col.field) });
        });
        const columnFields = availableColumns.map(({ field }) => field);
        const invalidColumnKeys = (columnKeys || []).filter((key) => !columnFields.includes(key));
        if (invalidColumnKeys.length && handleColumnVisibilityChange) {
            invalidColumnKeys.forEach((field) => {
                handleColumnVisibilityChange({ field });
            });
        }
        return [displayCols];
    }, [availableColumns, columnKeys, handleColumnVisibilityChange]);
    const toolbar = () => {
        const shouldEnableExport = isExportEnabled !== undefined ? isExportEnabled : true;
        return (react_1.default.createElement(ux_1.DashboardDataGridToolbar, { actions: gridActions, areColumnsHidden: areColumnsHidden, areFiltersEnabled: areFiltersEnabled, handleExport: handleExport, handleToggleAutoRefresh: shouldEnableExport ? handleToggleAutoRefresh : undefined, isExportEnabled: shouldEnableExport, isHeaderShown: isHeaderShown, isLazyLoading: isLazyLoading, isLoading: isLoading, isServerSideExport: true, language: language, lastLoadedDate: lastLoadedDate, rowCount: rowCount, shouldAutoRefresh: shouldAutoRefresh, t: t }));
    };
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: displayCols, containerClassName: containerClassName, currentSort: currentSort, data: data || [], defaultSort: defaultSort, filterMode: filterMode, gridHeight: (pageSize + 2.15) * tableRowHeight, handleCellClick: handleCellClick, handleColumnVisibilityChange: handleColumnVisibilityChange, handleFilterChange: handleFilterChange, handleTotalRowDisplayText: quote_utils_1.getTotalGridRowsText, hasCustomHeader: true, isActionsOverlayEnabled: isActionsOverlayEnabled, isLoading: isLoading, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, onSortChange: handleSortingChange, pageSize: pageSize, paginationMode: paginationMode, renderActionsContent: renderActionsContent, rowCount: rowCount, rowHeight: tableRowHeight, selectionModel: selectedRows, shouldDisableMultipleSelection: true, shouldHideFooterSelectedRowCount: true, sortingMode: sortingMode, t: t, toolbar: toolbar }));
};
exports.GeneralCSPDataGrid = GeneralCSPDataGrid;
