"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormWrapper = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const FormWrapper_styles_1 = require("./FormWrapper.styles");
const FormWrapper = (props) => {
    const { children, handleCancel, handleDelete, handleSubmit, isCardDisplay, isSaveDisabled, isSaving, t, title, } = props;
    const classes = (0, FormWrapper_styles_1.useStyles)();
    const [isInDelete, setInDelete] = (0, react_1.useState)(false);
    let deleteButton = react_1.default.createElement(react_1.default.Fragment, null);
    if (handleDelete !== undefined) {
        deleteButton = (react_1.default.createElement(ux_1.FlatButton, { className: classes.deleteButton, color: "error", disabled: isSaving, onClick: () => {
                setInDelete(true);
            } },
            react_1.default.createElement(ux_1.DeleteIcon, null)));
    }
    let titleDisplay = react_1.default.createElement(react_1.default.Fragment, null);
    if (title) {
        titleDisplay = (react_1.default.createElement(ux_1.Box, { mb: 3 },
            react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyLarge" }, title))));
    }
    const content = (react_1.default.createElement(react_1.default.Fragment, null,
        children,
        react_1.default.createElement(ux_1.Box, { mt: 3, style: { position: 'relative' } },
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, justifyContent: "flex-start", spacing: 2, xs: true },
                    react_1.default.createElement(ux_1.Grid, { item: true }, deleteButton),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.StandardButton, { isDisabled: isSaving, onClick: handleCancel }, t('common:cancel')))),
                react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
                    react_1.default.createElement(__1.SaveButton, { isDisabled: isSaveDisabled, isSaving: isSaving, t: t }))),
            react_1.default.createElement("div", { className: classes.deleteWrapper, style: { display: isInDelete ? 'block' : 'none' } },
                react_1.default.createElement(ux_1.Collapse, { in: isInDelete },
                    react_1.default.createElement(ux_1.Box, { className: classes.deleteContainer, width: '100%' },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "space-between", style: { height: '100%', marginTop: '-16px' } },
                            react_1.default.createElement(ux_1.Grid, { item: true, style: { paddingLeft: '32px' }, xs: true },
                                react_1.default.createElement(ux_1.StandardButton, { isDisabled: isSaving, onClick: () => setInDelete(false), textClassName: classes.cancelDelete }, t('common:cancel'))),
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", style: { paddingRight: '32px' }, xs: true },
                                react_1.default.createElement(ux_1.FlatButton, { color: "error", disabled: isSaving, onClick: handleDelete, startIcon: isSaving ? react_1.default.createElement(ux_1.CircularProgress, { size: 24 }) : null }, t('common:confirm'))))))))));
    return (react_1.default.createElement("form", { onSubmit: handleSubmit, style: { width: '100%' } },
        titleDisplay,
        isCardDisplay ? (react_1.default.createElement(ux_1.Box, { pb: 3 },
            react_1.default.createElement(ux_1.FlatCard, null, content))) : (content)));
};
exports.FormWrapper = FormWrapper;
