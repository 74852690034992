"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetDisplay = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const RateSheetDisplay = ({ handleActivateRateSheet, handleCancel, handleDownloadRateSheet, handleRouteToLane, handleRouteToRateSheet, handleSetEdit, handleUpdateRateSheet, isSaving, lanes, language, rateSheet, t, versions, }) => {
    var _a, _b;
    const [isDownloading, setDownloading] = (0, react_1.useState)(null);
    const formControls = (0, react_hook_form_1.useForm)({
        defaultValues: rateSheet,
    });
    const laneLookup = (0, react_1.useMemo)(() => {
        const lookup = {};
        (lanes || []).forEach((lane) => {
            lookup[lane.data.id] = lane.data;
        });
        return lookup;
    }, [lanes]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(__1.SimpleList, { items: [
                { header: t('common:name'), value: rateSheet === null || rateSheet === void 0 ? void 0 : rateSheet.name },
                { header: t('common:pricingModel'), value: t(`common:${rateSheet.pricingModel}`) },
                { header: t('common:usedByLanes'), value: ((rateSheet === null || rateSheet === void 0 ? void 0 : rateSheet.lanes) || []).length },
                { header: t('common:version'), value: rateSheet === null || rateSheet === void 0 ? void 0 : rateSheet.version },
                {
                    header: t('common:download'),
                    value: (react_1.default.createElement(ux_1.StandardButton, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setDownloading(rateSheet.id);
                            try {
                                yield handleDownloadRateSheet(rateSheet);
                            }
                            finally {
                                setDownloading(null);
                            }
                        }) }, isDownloading === rateSheet.id ? (react_1.default.createElement(ux_1.CircularProgress, { size: 16 })) : (react_1.default.createElement(ux_1.Typography, { color: "primaryLight" }, t('common:download'))))),
                },
            ] }),
        react_1.default.createElement(ux_1.Box, { mt: 3, p: 2, style: { background: '#f9f9f9', borderRadius: '16px' } },
            react_1.default.createElement("form", { onSubmit: formControls.handleSubmit(handleUpdateRateSheet) },
                react_1.default.createElement(react_hook_form_1.Controller, { control: formControls.control, defaultValue: '', name: "expirationDate", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                        return (react_1.default.createElement(__1.DatePickerInput, { error: error, label: t('common:expirationDate'), language: language, minDate: new Date(), onChange: onChange, placeholder: t('common:datePlaceholder'), shouldShowFullDate: true, value: value || '' }));
                    } }),
                react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: formControls.control, defaultValue: Boolean(rateSheet.active), name: "active", render: ({ field: { onChange, value } }) => {
                            return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Switch, { checked: value, color: "primary", onChange: onChange }), label: react_1.default.createElement(ux_1.Box, { ml: 1 }, t(`common:active`)) }));
                        } })),
                react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: formControls.control, defaultValue: Boolean(rateSheet.isGlobal), name: "isGlobal", render: ({ field: { onChange, value } }) => {
                            return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Switch, { checked: value, color: "primary", onChange: onChange }), label: react_1.default.createElement(ux_1.Box, { ml: 1 }, t(`common:shouldSetAsGlobal`)) }));
                        } })),
                react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "flex-end" },
                    react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t })))),
        Boolean(versions.length) && (react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, t('common:versions')),
            react_1.default.createElement(ux_1.DashboardDataGrid, { actionColumnCondition: (row) => {
                    return !row.active;
                }, columns: [
                    {
                        field: 'active',
                        headerName: ' ',
                        renderCell: ({ value }) => {
                            return react_1.default.createElement(ux_1.DataGridUnreadIndicator, { hasBeenRead: !value });
                        },
                        width: 25,
                    },
                    {
                        field: 'id',
                        headerName: ' ',
                        renderCell: ({ row }) => {
                            return (react_1.default.createElement(ux_1.StandardButton, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    setDownloading(row.id);
                                    try {
                                        yield handleDownloadRateSheet(row);
                                    }
                                    finally {
                                        setDownloading(null);
                                    }
                                }) }, isDownloading === row.id ? (react_1.default.createElement(ux_1.CircularProgress, { size: 16 })) : (react_1.default.createElement(ux_1.TruxwebDownloadIcon, null))));
                        },
                        width: 50,
                    },
                    {
                        field: 'version',
                        headerName: t('common:version'),
                        renderCell: ({ row, value }) => {
                            return (react_1.default.createElement(ux_1.Typography, { color: "primaryLight", onClick: () => {
                                    handleRouteToRateSheet(row.id);
                                }, style: { cursor: 'pointer' } }, value));
                        },
                        width: 25,
                    },
                    {
                        field: 'dateUpdated',
                        flex: 1,
                        headerName: t('common:dateUpdated'),
                        renderCell: ({ value }) => {
                            return react_1.default.createElement(ux_1.Typography, null, (0, utils_1.formatDate)(value));
                        },
                    },
                ], data: versions, gridHeight: 52 * ((versions === null || versions === void 0 ? void 0 : versions.length) + 2), isActionsOverlayEnabled: true, renderActionsContent: (row, manageOverlayState) => {
                    return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "center", style: { height: '100%' } },
                        react_1.default.createElement(ux_1.StandardButton, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield handleActivateRateSheet(row);
                                if (manageOverlayState) {
                                    manageOverlayState(false);
                                }
                            }) }, isSaving ? react_1.default.createElement(ux_1.CircularProgress, null) : t('common:setActive'))));
                }, rowHeight: 52, shouldSuppressFooter: true, t: t }))),
        Boolean((_a = rateSheet === null || rateSheet === void 0 ? void 0 : rateSheet.lanes) === null || _a === void 0 ? void 0 : _a.length) && (react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, t('common:lanes')),
            react_1.default.createElement(ux_1.DashboardDataGrid, { columns: [
                    {
                        field: 'laneId',
                        flex: 1,
                        headerName: t('common:carrierReferenceNumber'),
                        renderCell: ({ row }) => {
                            var _a;
                            const lane = row.laneId;
                            return (react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", style: { cursor: 'pointer' } }, ((_a = laneLookup[lane]) === null || _a === void 0 ? void 0 : _a.carrierReference) || t('common:unknown')));
                        },
                    },
                    {
                        field: 'id',
                        flex: 1,
                        headerName: t('common:locationType'),
                        renderCell: ({ row }) => {
                            return (react_1.default.createElement(ux_1.Typography, null, row.locationType
                                ? t(`common:${row.locationType.toLowerCase()}`)
                                : t('common:na')));
                        },
                    },
                ], data: (rateSheet === null || rateSheet === void 0 ? void 0 : rateSheet.lanes) || [], gridHeight: 52 * (((_b = rateSheet === null || rateSheet === void 0 ? void 0 : rateSheet.lanes) === null || _b === void 0 ? void 0 : _b.length) + 2), handleRowClick: ({ row }) => {
                    handleRouteToLane(row.laneId);
                }, isActionsOverlayEnabled: true, rowHeight: 52, shouldSuppressFooter: true, t: t }))),
        react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, null,
                    react_1.default.createElement(ux_1.StandardButton, { onClick: handleCancel }, t('common:cancel'))),
                react_1.default.createElement(ux_1.Grid, null,
                    react_1.default.createElement(ux_1.FlatButton, { color: "primaryLight", onClick: handleSetEdit, variant: "contained" }, t('common:uploadNewVersion')))))));
};
exports.RateSheetDisplay = RateSheetDisplay;
