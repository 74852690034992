// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinearFeetDimensionV1 = exports.CarrierPricingMetricConfigOptionTypeV1 = void 0;
var CarrierPricingMetricConfigOptionTypeV1;
(function (CarrierPricingMetricConfigOptionTypeV1) {
    CarrierPricingMetricConfigOptionTypeV1["NUMBER"] = "NUMBER";
})(CarrierPricingMetricConfigOptionTypeV1 || (exports.CarrierPricingMetricConfigOptionTypeV1 = CarrierPricingMetricConfigOptionTypeV1 = {}));
var LinearFeetDimensionV1;
(function (LinearFeetDimensionV1) {
    LinearFeetDimensionV1["LENGTH"] = "LENGTH";
    LinearFeetDimensionV1["WIDTH"] = "WIDTH";
})(LinearFeetDimensionV1 || (exports.LinearFeetDimensionV1 = LinearFeetDimensionV1 = {}));
