// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalAPIIntegrationsV1 = exports.TruckloadTypeV1 = exports.RangeUnitOfMeasureV1 = exports.CurrencyV1 = void 0;
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var RangeUnitOfMeasureV1;
(function (RangeUnitOfMeasureV1) {
    RangeUnitOfMeasureV1["mi"] = "mi";
    RangeUnitOfMeasureV1["km"] = "km";
})(RangeUnitOfMeasureV1 || (exports.RangeUnitOfMeasureV1 = RangeUnitOfMeasureV1 = {}));
var TruckloadTypeV1;
(function (TruckloadTypeV1) {
    TruckloadTypeV1["FTL"] = "FTL";
    TruckloadTypeV1["LTL"] = "LTL";
})(TruckloadTypeV1 || (exports.TruckloadTypeV1 = TruckloadTypeV1 = {}));
var ExternalAPIIntegrationsV1;
(function (ExternalAPIIntegrationsV1) {
    ExternalAPIIntegrationsV1["MORNEAU"] = "MORNEAU";
    ExternalAPIIntegrationsV1["CSA"] = "CSA";
})(ExternalAPIIntegrationsV1 || (exports.ExternalAPIIntegrationsV1 = ExternalAPIIntegrationsV1 = {}));
