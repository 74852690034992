import {
  type AppThunk,
  setSearchRequestIdError,
  setSearchRequestIdLoaded,
  setSearchRequestIdLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';
import { type TBookingSearchRequestV1 } from '@truxweb/schemas';

export const createSearchRequest =
  (query: TBookingSearchRequestV1): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setSearchRequestIdLoading());
      const { messageId } = await createSearchRequestAction(query);

      dispatch(setSearchRequestIdLoaded({ messageId }));
    } catch (error) {
      dispatch(setSearchRequestIdError({ error: error.message }));
    }
  };

export const createSearchRequestAction = async (
  query: TBookingSearchRequestV1
): Promise<{ messageId: number }> => {
  return await makeRequestToApiGateway(
    'bookingPostV1BookingSearch',
    undefined,
    {
      'Content-Type': 'application/json',
    },
    query
  );
};
