// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalAPIIntegrationsV1 = void 0;
var ExternalAPIIntegrationsV1;
(function (ExternalAPIIntegrationsV1) {
    ExternalAPIIntegrationsV1["MORNEAU"] = "MORNEAU";
    ExternalAPIIntegrationsV1["CSA"] = "CSA";
})(ExternalAPIIntegrationsV1 || (exports.ExternalAPIIntegrationsV1 = ExternalAPIIntegrationsV1 = {}));
