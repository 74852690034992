"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        background: '#e9e9e9',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: 16,
        padding: 8,
    },
}));
