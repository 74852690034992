import { ApiServices, AWS_REGION } from '../config';
import { Auth, Signer } from 'aws-amplify';
import { HTTPMethod } from 'http-method-enum';

type TSignRequestParams = {
  serviceName: string;
  url: string;
  method: HTTPMethod;
  headers?: Record<string, any>;
  data?: Record<string, any>;
};
export const signRequest = async ({
  data,
  headers,
  method,
  serviceName,
  url,
}: TSignRequestParams): Promise<{ [key: string]: any }> => {
  const { accessKeyId, secretAccessKey, sessionToken } = await Auth.currentCredentials();
  const serviceUrl = ApiServices[serviceName];
  const requestParams: {
    headers: Record<string, any>;
    method: HTTPMethod;
    url: string;
    data?: string;
  } = {
    headers: headers || {},
    method,
    url: `${serviceUrl}${url}`,
  };

  if (data) {
    requestParams.data = JSON.stringify(data);
  }

  return Signer.sign(
    requestParams,
    // NOTE: Linting disabled due to external API requirements
    /* eslint-disable camelcase */
    {
      access_key: accessKeyId,
      secret_key: secretAccessKey,
      session_token: sessionToken,
    },
    /* eslint-enable camelcase */
    {
      region: AWS_REGION,
      service: 'execute-api',
    }
  );
};
