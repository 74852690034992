/* eslint-disable max-params */
import { availableEndpoints, IS_SENTRY_RELEASE_ENABLED } from '../config';
import { extractErrorCode, extractErrorMessage } from '@truxweb/errors';
import { API } from 'aws-amplify';
import { captureException } from '@sentry/nextjs';
import { getEndpoint } from '@truxweb/api-utils';
import { signRequest } from '.';

export const makeRequestToApiGateway = async <T>(
  requestAlias: keyof typeof availableEndpoints,
  params?: Record<string, any>,
  requestHeaders?: Record<string, any>,
  body?: Record<string, any>,
  query?: Record<string, any>
): Promise<T> => {
  try {
    const requestProperties = availableEndpoints[requestAlias];
    if (!requestProperties) {
      throw new Error(
        `${requestAlias} is not supported. Available Endpoints: ${Object.keys(
          availableEndpoints
        ).join()}`
      );
    }
    const { endpoint } = requestProperties;
    const url = getEndpoint(requestAlias, endpoint, params, query);

    const { method, service } = requestProperties;
    const request = {
      data: body,
      headers: requestHeaders,
      method,
      serviceName: service,
      url,
    };

    const { headers } = await signRequest(request);

    const requestData: {
      headers: Record<string, any>;
      body?: Record<string, any>;
    } = { headers };

    if (body) {
      requestData.body = body;
    }

    let methodName = method.toLowerCase();
    if (methodName === 'delete') {
      methodName = 'del';
    }

    return await API[methodName](service, url, requestData);
  } catch (err) {
    err.message = extractErrorMessage(err).join(' ');
    err.code = extractErrorCode(err);
    if (IS_SENTRY_RELEASE_ENABLED) {
      captureException(err);
    }
    throw err;
  }
};

export const request = (params?: Record<string, any>, body?: Record<string, any>) => {
  return async (requestAlias: keyof typeof availableEndpoints): Promise<unknown> => {
    return makeRequestToApiGateway(requestAlias, params, undefined, body);
  };
};
