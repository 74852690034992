"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchResultsDataGrid = void 0;
const ux_1 = require("@truxweb/ux");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const quote_utils_1 = require("@truxweb/quote-utils");
const utils_1 = require("@truxweb/utils");
const SearchResultsDataGrid_styles_1 = require("./SearchResultsDataGrid.styles");
const ROW_HEIGHT = 64;
const GAP_INTERVAL = 1;
const BASE_TEST_ID = 'SearchResultsDataGrid';
const PAGE_SIZE = 10;
const SearchResultsDataGrid = ({ addAlert, bookingData, emptyGridDisplay, gridData, handleBooking, isAdminView, isLoading, langauge, quoteMaxSelection, renderCarrierName, renderCompanyProfileLink, selections, setSelections, shouldSuppressProfileLink, t, trackingAccessorialId, }) => {
    const [areTopBookingsSelected, setAreTopBookingsSelected] = (0, react_1.useState)(false);
    const classes = (0, SearchResultsDataGrid_styles_1.useStyles)({
        gapInterval: GAP_INTERVAL,
    });
    const resultsToDataMap = {};
    (bookingData || []).forEach((datum) => {
        resultsToDataMap[datum.carrierId] = datum;
    });
    const columns = [
        {
            align: 'left',
            field: 'carrierCompanyCode',
            headerAlign: 'left',
            headerName: t(`common:carrierCompanyCode`),
            hide: true,
        },
        {
            align: 'left',
            field: 'carrierName',
            flex: 1,
            headerAlign: 'left',
            headerName: t(`common:carrier`),
            renderCell: ({ row }) => {
                if (renderCarrierName && resultsToDataMap[row.carrierId]) {
                    return renderCarrierName(resultsToDataMap[row.carrierId]);
                }
                return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", style: { height: ROW_HEIGHT } },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { mt: 1.5 },
                            react_1.default.createElement(ux_1.DataGridColumnText, { testId: `${BASE_TEST_ID}-CarrierName`, text: row.carrierName }))),
                    react_1.default.createElement(ux_1.Grid, { item: true, style: { height: 20 } },
                        react_1.default.createElement(ux_1.Box, { mt: -1.5 },
                            react_1.default.createElement(ux_1.Rating, { precision: 0.5, readOnly: true, size: "small", value: row.carrierRating / utils_1.STAR_VALUE })))));
            },
            sortable: true,
            width: 230,
        },
        {
            align: 'center',
            field: 'accessorialIds',
            headerAlign: 'center',
            headerName: ' ',
            renderCell: ({ value }) => {
                if (value)
                    return (react_1.default.createElement(ux_1.Tooltip, { title: `${t('common:trackingAvailable')} ${t('common:shipperCarrierQuoteRequest-TRACKING-OPTIONAL-YES')}` },
                        react_1.default.createElement(ux_1.MapOutlinedIcon, { className: classes.trackingAvailable })));
                return (react_1.default.createElement(ux_1.Tooltip, { title: `${t('common:trackingAvailable')} ${t('common:shipperCarrierQuoteRequest-TRACKING-OPTIONAL-NO')}` },
                    react_1.default.createElement(ux_1.MapOutlinedIcon, null)));
            },
            sortable: true,
            valueGetter: ({ row }) => {
                var _a;
                return (_a = row.accessorialIds) === null || _a === void 0 ? void 0 : _a.includes(trackingAccessorialId);
            },
            width: 35,
        },
        {
            align: 'center',
            field: 'price',
            headerAlign: 'center',
            headerName: t(`common:price`),
            renderCell: ({ row }) => {
                const display = Boolean(row.price) ? (react_1.default.createElement(__1.PriceDisplay, { language: langauge, price: row.price, testId: `${BASE_TEST_ID}-Price` })) : (react_1.default.createElement(ux_1.Typography, { "data-testid": `${BASE_TEST_ID}-Price-TBD`, fontStyle: "semibold" }, t('common:tbd')));
                if (!isAdminView)
                    return display;
                return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", justifyContent: "center", spacing: 2 },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Tooltip, { title: `${t('common:dateUpdatedWithDate', {
                                dateUpdated: row.dateUpdated,
                            })} ` },
                            react_1.default.createElement(ux_1.Box, null, display)))));
            },
            sortable: true,
            valueGetter: ({ row }) => {
                if (Boolean(row.price)) {
                    return row.price.value;
                }
                return 0;
            },
            width: 185,
        },
    ];
    const emptyGridOverlay = (0, react_1.useCallback)(() => {
        if (isLoading)
            return react_1.default.createElement(react_1.default.Fragment, null);
        return emptyGridDisplay;
    }, [isLoading, emptyGridDisplay]);
    (0, react_1.useEffect)(() => {
        //set selections to state
        handleBooking(selections);
    }, [handleBooking, selections]);
    let gridHeight = 5.75 * ROW_HEIGHT;
    if (gridData.length) {
        gridHeight =
            gridData.length < PAGE_SIZE
                ? (gridData.length + 2.75) * ROW_HEIGHT
                : (PAGE_SIZE + 1.75) * ROW_HEIGHT;
    }
    return (react_1.default.createElement(ux_1.Container, { className: classes.container },
        react_1.default.createElement(ux_1.DashboardDataGrid, { actionColumnCondition: (row) => {
                return Boolean(row.hasActiveProfile);
            }, actionOverlayDirection: "left", columns: columns, data: gridData || [], gapInterval: GAP_INTERVAL, gridHeight: gridHeight, handleEmptyGrid: emptyGridOverlay, handleTotalRowDisplayText: quote_utils_1.getTotalGridRowsText, hasCustomHeader: true, isActionsOverlayAppended: true, isActionsOverlayEnabled: true, isCheckboxSelectionEnabled: true, isLoading: isLoading, onRowSelectionChange: (selectedIds) => {
                if (areTopBookingsSelected)
                    setAreTopBookingsSelected(false);
                const selectedCarriers = bookingData.filter((booking) => {
                    return selectedIds.includes(booking.carrierExtId);
                });
                if (selectedCarriers.length > quoteMaxSelection) {
                    addAlert({
                        message: t('search:maxQuoteSelection', { max: quoteMaxSelection }),
                        severity: 'warning',
                    });
                }
                else {
                    setSelections(selectedCarriers);
                }
            }, pageSize: PAGE_SIZE, paginationPosition: "center", renderActionsContent: (row, manageOverlayState) => {
                if (row.hasActiveProfile && !shouldSuppressProfileLink)
                    return renderCompanyProfileLink(row.carrierCompanyCode, row.carrierId, manageOverlayState);
                return react_1.default.createElement(react_1.default.Fragment, null);
            }, rowHeight: ROW_HEIGHT, selectionModel: selections.map(({ carrierExtId }) => {
                return carrierExtId;
            }), shouldDisplayRowCountTextInHeader: true, shouldHideFooterSelectedRowCount: true, t: t, toolbar: (displayTotals) => {
                if (gridData.length === 0)
                    return react_1.default.createElement(ux_1.Grid, { className: classes.toolbar });
                return (react_1.default.createElement(ux_1.Grid, { className: classes.toolbar },
                    react_1.default.createElement(ux_1.Box, { className: classes.toolbarItem },
                        react_1.default.createElement(__1.SearchSelectTopResults, { className: classes.toolbarLabel, dataSet: bookingData || [], isChecked: areTopBookingsSelected, onChange: (selections) => {
                                setSelections(selections);
                                setAreTopBookingsSelected(Boolean(selections === null || selections === void 0 ? void 0 : selections.length));
                            }, quoteMaxSelection: quoteMaxSelection, t: t })),
                    react_1.default.createElement(ux_1.Box, { className: classes.toolbarItem, justifyContent: "end" },
                        react_1.default.createElement(ux_1.Typography, { className: classes.toolbarLabel, variant: "body2" }, displayTotals))));
            } })));
};
exports.SearchResultsDataGrid = SearchResultsDataGrid;
