import { Box, FlatButton, FlatCard, Grid, RightHandSidebar } from '@truxweb/ux';
import { clearSearchResults, setLoad, setSearchFormData, updateUxState } from '../../stores';
import {
  convertFormDataToBookingQueryV1,
  loadDefinitionToSearch,
  transformI18nLocaleToLanguage,
} from '@truxweb/utils';
import {
  EPermissionV1,
  ETruckloadTypeV1,
  type TLoadParametersV1,
  type TQuoteSearchFormDataV1,
} from '@truxweb/schemas';
import { getLocalizedRoute, saveBookingQuery } from '../../utils';
import { LoadParametersList, LoadParametersSidebar } from '@truxweb/common-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCompanyData,
  useCompanyLocations,
  useLoadParameters,
  useRefresh,
  useSearchFormParams,
  useShipmentCredits,
  useShipmentEntityTags,
  useUxState,
} from '../../hooks';
import { CompanyLocationCardWrapper } from '../../components';
import { ShipperPage } from '../../pageTemplates';
import { TTruxwebPageContentProps } from '../../types';
import { updateLoadParametersAction } from '../../actions';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'shipments', 'search', 'select'];

type TLoadParamFormData = TLoadParametersV1 & {
  loadDefinition: TLoadParametersV1['loadDefinition'] & {
    accessorialMetdataSelections: any[];
    accessorialSelections: any[];
  };
};

export const LoadParameters = ({ pageId }: TTruxwebPageContentProps): JSX.Element => {
  const { i18n, t } = useTranslation(REQUIRED_NAMESPACES);
  const { handleRefresh, shouldRefresh } = useRefresh();
  const router = useRouter();
  const [companyData] = useCompanyData();
  const dispatch = useDispatch();
  const companyLocations = useCompanyLocations();
  const searchFormParams = useSearchFormParams();
  const shipmentCredits = useShipmentCredits(false);
  const loadParameters = useLoadParameters(shouldRefresh, t);
  const uxState = useUxState();
  const [editLoad, setEditLoad] = useState<null | TLoadParamFormData>(null);
  const [hasLoaded, setLoaded] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const tags = useShipmentEntityTags(shouldRefresh);

  const handleSearchWithLoad = useCallback(
    async (loadParameters: TLoadParametersV1) => {
      const companyLocationData = Object.values(companyLocations.data).map(({ data }) => data);
      const searchData = await loadDefinitionToSearch({
        companyLocations: companyLocationData,
        loadDefinition: loadParameters.loadDefinition,
        searchDate: new Date(),
        searchParams: searchFormParams.data,
      });

      const query = convertFormDataToBookingQueryV1(
        searchData as TQuoteSearchFormDataV1,
        searchFormParams.data,
        Boolean(shipmentCredits.data.length)
      );

      saveBookingQuery(query);
      dispatch(setSearchFormData({ data: searchData, shouldForceRefresh: false }));
      dispatch(clearSearchResults());
      dispatch(setLoad({ data: { ...loadParameters.loadDefinition, isExistingSearch: true } }));

      const resultsRoute = getLocalizedRoute(router.locale, 'results');
      router.push(resultsRoute);
    },
    [companyLocations, searchFormParams, shipmentCredits, dispatch, router]
  );
  const companyLocationData = useMemo(() => {
    if (!companyLocations.hasLoaded) return [];
    return Object.values(companyLocations.data).map(({ data }) => data);
  }, [companyLocations]);

  const handleEditLoad = useCallback(
    (load: TLoadParamFormData) => {
      setEditLoad(null);
      setTimeout(() => {
        setEditLoad(load);
      }, 0);
      const loadRoute = getLocalizedRoute(router.locale, 'loads');
      router.push(`${loadRoute}?loadId=${load.id}`);
      setSidebarOpen(true);
    },
    [setEditLoad, router]
  );

  const handleCreateLoad = useCallback(() => {
    const loadRoute = getLocalizedRoute(router.locale, 'loads');
    setEditLoad({
      companyExtId: companyData.companyExtId,
      dateCreated: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
      id: 0,
      loadDefinition: {
        accessorialIds: [],
        accessorialMetadata: [],
        accessorialMetdataSelections: [],
        accessorialSelections: [],
        cargoSpecifications: [],
        companyExtId: companyData.companyExtId,
        destinationId: 0,
        equipmentId: 1,
        isBookingDateFlexible: true,
        isTaxExempt: false,
        name: '',
        originId: 0,
        truckloadType: ETruckloadTypeV1.FTL,
      },
      userExtId: '',
    });
    setSidebarOpen(true);
    router.push(`${loadRoute}?loadId=create`);
  }, [router, setEditLoad, companyData, setSidebarOpen]);

  const handleClose = useCallback(() => {
    dispatch(updateUxState({ ...uxState, isAddLoadSidebarOpen: false }));
    const loadRoute = getLocalizedRoute(router.locale, 'loads');
    router.push(loadRoute);
    setEditLoad(null);
    setSidebarOpen(false);
  }, [setEditLoad, router, dispatch, uxState]);

  const handleEditLoadSubmit = useCallback(
    async (formData: TLoadParametersV1) => {
      await updateLoadParametersAction(formData);
      handleRefresh();
      handleClose();
    },

    [handleRefresh, handleClose]
  );

  useEffect(() => {
    if (router.query.loadId && !editLoad && loadParameters.hasLoaded && !hasLoaded) {
      const loadId = parseInt(router.query.loadId as string, 10);
      const load = loadParameters.data.find(({ id }) => {
        return loadId === id;
      });

      if (!load) handleClose();
      if (load) handleEditLoad(load._source);
      if (!hasLoaded) {
        setLoaded(true);
      }
    }
  }, [
    router,
    editLoad,
    setEditLoad,
    loadParameters,
    handleEditLoad,
    handleClose,
    hasLoaded,
    setLoaded,
  ]);

  return (
    <ShipperPage pageId={pageId} permissions={[EPermissionV1.VIEW_SAVED_LOAD]}>
      <Grid container justifyContent="flex-end">
        <Box mb={2} mt={2}>
          <FlatButton
            color="primaryLight"
            onClick={() => {
              dispatch(updateUxState({ ...uxState, isAddLoadSidebarOpen: true }));
              handleCreateLoad();
            }}
            variant="contained"
          >
            {t('common:addNewLoad')}
          </FlatButton>
        </Box>
      </Grid>

      <RightHandSidebar handleClose={handleClose} isOpen={isSidebarOpen}>
        <>
          {Boolean(editLoad) && (
            <LoadParametersSidebar
              companyLocations={companyLocationData}
              handleEditLoadSubmit={handleEditLoadSubmit}
              loadParameters={editLoad}
              onCancel={handleClose}
              renderCompanyLocation={(locationId: number) => {
                return <CompanyLocationCardWrapper companyLocationId={locationId} />;
              }}
              searchParams={searchFormParams.data}
              t={t}
            />
          )}
          {!editLoad && (
            <FlatCard>
              <></>
            </FlatCard>
          )}
        </>
      </RightHandSidebar>
      <LoadParametersList
        availableTags={tags?.data || []}
        data={loadParameters.data || []}
        handleEditLoad={handleEditLoad}
        handleSearchWithLoad={handleSearchWithLoad}
        isLoading={loadParameters.isLoading}
        language={transformI18nLocaleToLanguage(i18n.language)}
        lastLoadedDate={new Date(loadParameters.lastLoadedDate)}
        rowCount={loadParameters.rowCount}
        t={t}
      />
    </ShipperPage>
  );
};
