// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierPricingActionV1 = exports.AccessorialOptionV1 = exports.LinearFeetDimensionV1 = exports.CarrierPricingMetricV1 = void 0;
var CarrierPricingMetricV1;
(function (CarrierPricingMetricV1) {
    CarrierPricingMetricV1["TOTAL_PALLET_COUNT"] = "TOTAL_PALLET_COUNT";
    CarrierPricingMetricV1["PALLET_WEIGHT"] = "PALLET_WEIGHT";
    CarrierPricingMetricV1["PALLET_WIDTH"] = "PALLET_WIDTH";
    CarrierPricingMetricV1["PALLET_HEIGHT"] = "PALLET_HEIGHT";
    CarrierPricingMetricV1["PALLET_HEIGHT_CLAMP"] = "PALLET_HEIGHT_CLAMP";
    CarrierPricingMetricV1["PALLET_LENGTH"] = "PALLET_LENGTH";
    CarrierPricingMetricV1["LINEAR_FEET"] = "LINEAR_FEET";
    CarrierPricingMetricV1["LINEAR_FEET_CLAMP"] = "LINEAR_FEET_CLAMP";
    CarrierPricingMetricV1["TOTAL_ACTUAL_WEIGHT"] = "TOTAL_ACTUAL_WEIGHT";
    CarrierPricingMetricV1["LABRANCHE_VOLUME"] = "LABRANCHE_VOLUME";
    CarrierPricingMetricV1["MESSAGERS_VOLUME"] = "MESSAGERS_VOLUME";
    CarrierPricingMetricV1["MINIMAX_VOLUME"] = "MINIMAX_VOLUME";
})(CarrierPricingMetricV1 || (exports.CarrierPricingMetricV1 = CarrierPricingMetricV1 = {}));
var LinearFeetDimensionV1;
(function (LinearFeetDimensionV1) {
    LinearFeetDimensionV1["LENGTH"] = "LENGTH";
    LinearFeetDimensionV1["WIDTH"] = "WIDTH";
})(LinearFeetDimensionV1 || (exports.LinearFeetDimensionV1 = LinearFeetDimensionV1 = {}));
var AccessorialOptionV1;
(function (AccessorialOptionV1) {
    AccessorialOptionV1["DANGEROUS_GOODS"] = "DANGEROUS_GOODS";
    AccessorialOptionV1["CARGO_INSURANCE"] = "CARGO_INSURANCE";
    AccessorialOptionV1["MULTI_STOP"] = "MULTI_STOP";
    AccessorialOptionV1["SCHEDULED_PICKUP"] = "SCHEDULED_PICKUP";
    AccessorialOptionV1["SCHEDULED_DELIVERY"] = "SCHEDULED_DELIVERY";
    AccessorialOptionV1["RESIDENTIAL_PICKUP"] = "RESIDENTIAL_PICKUP";
    AccessorialOptionV1["RESIDENTIAL_DELIVERY"] = "RESIDENTIAL_DELIVERY";
    AccessorialOptionV1["TAILGATE_PICKUP"] = "TAILGATE_PICKUP";
    AccessorialOptionV1["TAILGATE_DELIVERY"] = "TAILGATE_DELIVERY";
    AccessorialOptionV1["EXPEDITED_DELIVERY"] = "EXPEDITED_DELIVERY";
    AccessorialOptionV1["TARP"] = "TARP";
    AccessorialOptionV1["DROP_TRAILER"] = "DROP_TRAILER";
    AccessorialOptionV1["GENSET_CHARGE"] = "GENSET_CHARGE";
    AccessorialOptionV1["PRE_PULL"] = "PRE_PULL";
    AccessorialOptionV1["TRACKING"] = "TRACKING";
    AccessorialOptionV1["TRUCK_ONLY"] = "TRUCK_ONLY";
    AccessorialOptionV1["INSIDE_DELIVERY"] = "INSIDE_DELIVERY";
    AccessorialOptionV1["CURBSIDE_DELIVERY"] = "CURBSIDE_DELIVERY";
    AccessorialOptionV1["LIMITED_ACCESS_PICKUP"] = "LIMITED_ACCESS_PICKUP";
    AccessorialOptionV1["LIMITED_ACCESS_DELIVERY"] = "LIMITED_ACCESS_DELIVERY";
    AccessorialOptionV1["WHITE_GLOVE_PICKUP"] = "WHITE_GLOVE_PICKUP";
    AccessorialOptionV1["WHITE_GLOVE_DELIVERY"] = "WHITE_GLOVE_DELIVERY";
    AccessorialOptionV1["PREVENT_FREEZE"] = "PREVENT_FREEZE";
})(AccessorialOptionV1 || (exports.AccessorialOptionV1 = AccessorialOptionV1 = {}));
var CarrierPricingActionV1;
(function (CarrierPricingActionV1) {
    CarrierPricingActionV1["EXCLUDE"] = "EXCLUDE";
    CarrierPricingActionV1["SPOT_QUOTE"] = "SPOT_QUOTE";
    CarrierPricingActionV1["CUBING"] = "CUBING";
    CarrierPricingActionV1["STACKABLE_ADJ"] = "STACKABLE_ADJ";
    CarrierPricingActionV1["FUEL_ADJ"] = "FUEL_ADJ";
    CarrierPricingActionV1["PALLET_COUNT_ADJ"] = "PALLET_COUNT_ADJ";
})(CarrierPricingActionV1 || (exports.CarrierPricingActionV1 = CarrierPricingActionV1 = {}));
