import {
  addCarrierDocumentToShipmentV2,
  addComment,
  addShipperDocumentToShipment,
  addTempShipmentComment,
  downloadCarrierShipmentDocument,
  downloadShipperShipmentDocument,
  getShipmentByRef,
  getShipmentShareLink,
  markCommentsAsRead,
  postCommentAdd,
  updateShipmentRedux,
  updateShipmentShareState,
  updateShipperShipmentLocation,
  uploadCommentAttachments,
} from '../../actions';
import { Box, Dialog, DialogContent, FlatButton, Grid, Typography } from '@truxweb/ux';
import {
  CAN_CARRIER_ADD_ADDITIONAL_CHARGES,
  GOOGLE_MAPS_API_KEY,
  QUOTE_SIDEBAR_REFRESH_MS,
  SHIPMENT_DOCUMENT_UPLOAD,
} from '../../config';
import { clearSearchResults, setLoad, setSearchFormData } from '../../stores';
import { convertFormDataToBookingQueryV1, transformI18nLocaleToLanguage } from '@truxweb/utils';
import {
  EPermissionV1,
  EShipmentDocumentTypeV1,
  EShipmentLocationTypeV1,
  EShipmentStatusV1,
  EUserTypeV1,
  type TAddCommentPayloadV1,
  type TCompanyV1,
  TQuoteSearchFormDataV1,
  type TShipmentDocumentToUploadV1,
  type TShipmentDocumentV1,
  type TShipmentLocationV1,
  type TShipmentV1,
} from '@truxweb/schemas';
import { getLocalizedRoute, hasWindow, saveBookingQuery } from '../../utils';
import {
  getOriginAndDestinationFromShipmentLocations,
  shipmentToLoadDefinition,
  shipmentToSearch,
} from '@truxweb/shipment-utils';
import { PageLink, SubscriptionMarketing, TruxiiMessage } from '..';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ShipmentsDashboardSidebar,
  ShipmentShareLink,
  type TOnDocumentUploadSuccessInput,
  type TShipmentLocationType,
  type TShipmentSidebarSections,
} from '@truxweb/common-components';
import { type TAddCarrierDocumentDetailsV1, type TShipmentCommentAttachment } from '../../types';
import { Trans, useTranslation } from 'next-i18next';
import {
  useAlerts,
  useErrorHandling,
  useInterval,
  usePermissions,
  usePublicCarrierProfileData,
  useSearchFormParams,
  useShipmentComments,
  useShipmentCredits,
  useShipmentDetailsByRef,
  useShipmentDocumentTypes,
  useShipmentEntityTags,
  useTimeout,
  useUserData,
} from '../../hooks';
import { uploadFileToBucket } from '../../apiUtils';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useStyles } from './ShipmentsDashboardSidebarWrapper.styles';

const REQUIRED_NAMESPACES = ['common'];

type TShipmentsDashboardSidebarWrapperProps = {
  shipmentRef: string;
  handleClose: () => void;
  defaultExpandedSection?: TShipmentSidebarSections;
  baseRoute: string;
  handleListRefresh: () => void;
};

export const ShipmentsDashboardSidebarWrapper = ({
  baseRoute,
  defaultExpandedSection,
  handleClose,
  handleListRefresh,
  shipmentRef,
}: TShipmentsDashboardSidebarWrapperProps): JSX.Element => {
  const [hasSidebarLoaded, setHasSidebarLoaded] = useState(false);
  const errorHandler = useErrorHandling();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [isInError, setInError] = useState(false);
  const [isSubscriptionDialogShown, setSubscriptionDialogShown] = useState(false);
  const [locations, setLocations] = useState<TShipmentLocationType>({
    destinations: [],
    origin: null,
  });
  const [sidebarSections, setSidebarSections] = useState<Record<TShipmentSidebarSections, boolean>>(
    {
      accessorial: false,
      comments: false,
      documents: false,
      equipment: false,
      history: false,
      orderSummary: false,
      quote: false,
      shares: false,
      tracking: false,
      transactions: false,
    }
  );
  const shareLinkPrefix = hasWindow()
    ? `${window.location.protocol}//${window.location.host}/public/`
    : '';

  const shipmentCredits = useShipmentCredits(false);
  const tags = useShipmentEntityTags();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { addAlert } = useAlerts();
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const { locale } = router;
  const language = transformI18nLocaleToLanguage(locale);
  const { userData, userType } = useUserData();
  const {
    data: shipment,
    hasLoaded: hasShipmentLoaded,
    isLoading: isShipmentLoading,
  } = useShipmentDetailsByRef(userType, shipmentRef, shouldRefetch);
  const carrierCompanyCode = shipment?.shipment?.carrierCompanyCode;

  const comments = useShipmentComments(shipmentRef);
  const { data: searchFormParams, hasLoaded: haveSearchParamsLoaded } = useSearchFormParams();
  const [canSetTaxExemptState, havePermissionsEvaluated] = usePermissions([
    EPermissionV1.CAN_SET_NON_TAXABLE_SHIPMENT,
  ]);

  const otherUserType = Object.values(EUserTypeV1).filter((type) => {
    return ![userType].includes(type);
  });

  const [shipmentDocumentTypes, haveDocumentTypesLoaded] = useShipmentDocumentTypes();
  const [canManageShipperData, haveShipperPermissionsEvaluated] = usePermissions([
    EPermissionV1.MANAGE_SHIPPER_DATA,
  ]);
  const [canManageCarrierData, haveCarrierPermissionsEvaluated] = usePermissions([
    EPermissionV1.MANAGE_CARRIER_DATA,
  ]);

  const [carrierData] = usePublicCarrierProfileData(carrierCompanyCode, language, shouldRefetch);

  // NOTE: This is a permission - EPermissionV1.SHARE_SHIPMENT - but there has not
  // been a decision as to where / when to restrict it (ie. a subscription feature
  // or general feature) once that is done, this will be a usePermissions eval
  const canShareShipment = true;

  const handleSidebarSections = useCallback(
    (sectionName: string, isExpanded: boolean) => {
      setSidebarSections((value) => {
        return { ...value, [sectionName]: isExpanded };
      });
      router.push(`${baseRoute}?shipmentRef=${shipmentRef}&${sectionName}=1`);
    },
    [setSidebarSections, shipmentRef, baseRoute, router]
  );

  const onRouteToSubscription = useCallback(() => {
    setSubscriptionDialogShown(true);
  }, [setSubscriptionDialogShown]);

  const handleCloseSubscription = useCallback(() => {
    setSubscriptionDialogShown(false);
  }, [setSubscriptionDialogShown]);

  const handleSubscriptionSuccess = useTimeout(
    useCallback(() => {
      setSubscriptionDialogShown(false);
    }, [setSubscriptionDialogShown]),
    2000
  );
  const onDocumentUploadSuccess = useCallback(
    async (
      {
        documentLocation,
        documentName,
        documentOwnerType,
        shipmentDocumentType,
        shipmentDocumentTypeId,
      }: TOnDocumentUploadSuccessInput,
      formData?: Record<string, any>
    ): Promise<void> => {
      setIsLoading(true);
      setIsUploadingPerTypeState((prev) => {
        if (!prev[shipmentDocumentType]) {
          return {
            ...prev,
            [shipmentDocumentType]: true,
          };
        }
        return prev;
      });

      const shipmentDocument: TShipmentDocumentV1 = {
        documentLocation,
        documentName,
        documentOwnerId: userData.extId,
        documentOwnerType,
        isActive: true,
        shipmentDocumentType,
        shipmentDocumentTypeId,
        shipmentId: shipment.shipment.id,
      };

      // Map any formData to a schema for specific BE postUpload actions...
      let additionalDocInformation: TAddCarrierDocumentDetailsV1 = undefined;
      if (formData) {
        additionalDocInformation = {
          additionalCharges: formData.additionalCharges || [],
          billOfLading: formData.shouldInputBillOfLading && {
            billOfLading: formData.billOfLading,
            trackingId: formData.trackingId,
          },
          pickupDate: formData.bookingDate || undefined,
        };
      }

      if (canManageShipperData && !canManageCarrierData) {
        await addShipperDocumentToShipment(shipmentDocument);
      }
      if (!canManageShipperData && canManageCarrierData) {
        await addCarrierDocumentToShipmentV2(shipmentDocument, additionalDocInformation);
      }
      setIsLoading(false);
      setIsUploadingPerTypeState((prev) => {
        if (prev[shipmentDocumentType]) {
          return {
            ...prev,
            [shipmentDocumentType]: false,
          };
        }
        return prev;
      });
      setShouldRefetch(true);
      handleListRefresh();
    },
    [
      setIsLoading,
      handleListRefresh,
      userData?.extId,
      shipment?.shipment?.id,
      setShouldRefetch,
      canManageShipperData,
      canManageCarrierData,
    ]
  );

  const handleRefresh = useCallback(() => {
    setShouldRefetch(true);
  }, [setShouldRefetch]);

  const onEditShipment = useCallback(
    async (property: string, shipment: TShipmentV1) => {
      dispatch(
        updateShipmentRedux(userType, shipment, {
          cb: () => {
            // IF we are updating this shipment to delivered we need to add in a prompt
            if (
              property === t('common:status') &&
              shipment.status === EShipmentStatusV1.DELIVERED
            ) {
              addAlert({
                message: t('common:promptForProofOfDelivery'),
                severity: 'success',
              });
            }
            handleRefresh();

            handleListRefresh();
          },
          property,
          t,
        })
      );
    },
    [handleRefresh, t, userType, dispatch, addAlert, handleListRefresh]
  );

  const onEditShipmentLocation = useCallback(
    async (location: TShipmentLocationV1, locationType: EShipmentLocationTypeV1) => {
      dispatch(
        updateShipperShipmentLocation(
          shipmentRef,
          location,
          t(`common:${locationType}-externalReferenceNumber`),
          t
        )
      );
      handleListRefresh();
    },
    [shipmentRef, t, dispatch, handleListRefresh]
  );

  const renderQuoteLink = useCallback(
    (quoteId: number | undefined, _companyType: EUserTypeV1, linkText?: string) => {
      if (!quoteId) return <></>;
      return (
        <Box mt={-0.15}>
          <PageLink pageId={'requests'} urlParams={[{ name: 'quoteRequestId', value: quoteId }]}>
            <Typography color="primaryLight">{linkText || t('common:link')}</Typography>
          </PageLink>
        </Box>
      );
    },
    [t]
  );

  // Upload doc -- START
  const onDocumentUploadFailure = useCallback(
    (shipmentDocumentType: EShipmentDocumentTypeV1) => {
      setIsLoading(false);
      setIsUploadingPerTypeState((prev) => {
        if (prev[shipmentDocumentType]) {
          return {
            ...prev,
            [shipmentDocumentType]: false,
          };
        }
        return prev;
      });
    },
    [setIsLoading]
  );

  const getDocumentOwnerIdByType = useCallback(
    (documentOwnerType: EUserTypeV1): string => {
      // SHIPPER or CARRIER only
      let ret = ``;
      switch (documentOwnerType) {
        case EUserTypeV1.CARRIER:
          ret = shipment.shipment?.carrierExtId || '';
          break;
        case EUserTypeV1.SHIPPER:
          ret = shipment.shipment?.shipperExtId || '';
          break;
        default:
          ret = '';
      }
      return ret;
    },
    [shipment?.shipment]
  );

  const getDocumentMetadataForUpload = useCallback(
    (
      shipmentRef: string,
      files: FileList,
      { documentOwnerType, documentType, id }: TShipmentDocumentToUploadV1
    ): TOnDocumentUploadSuccessInput => {
      const documentLocation = `${shipmentRef}/${documentOwnerType.toLowerCase()}/${getDocumentOwnerIdByType(
        documentOwnerType
      )}/${documentType}/${files[0].name}`;

      return {
        documentLocation,
        documentName: files[0].name,
        documentOwnerType,
        shipmentDocumentType: documentType,
        shipmentDocumentTypeId: id,
      };
    },
    [getDocumentOwnerIdByType]
  );

  const refreshShipmentDetails = useCallback(() => {
    if (canManageCarrierData) {
      dispatch(getShipmentByRef(EUserTypeV1.CARRIER, shipment.shipment.shipmentRef));
    } else if (canManageShipperData) {
      dispatch(getShipmentByRef(EUserTypeV1.SHIPPER, shipment.shipment.shipmentRef));
    }
  }, [dispatch, canManageCarrierData, canManageShipperData, shipment?.shipment?.shipmentRef]);

  const handleDownloadDocument = useCallback(
    async (documentIds: number[]): Promise<string> => {
      let strFile: string = null;

      if (canManageCarrierData) {
        strFile = await downloadCarrierShipmentDocument(documentIds);
        dispatch(getShipmentByRef(EUserTypeV1.CARRIER, shipment.shipment.shipmentRef));
      } else if (canManageShipperData) {
        strFile = await downloadShipperShipmentDocument(documentIds);
        dispatch(getShipmentByRef(EUserTypeV1.SHIPPER, shipment.shipment.shipmentRef));
      } else {
        console.warn(`The current user is not allowed to download any documents`);
      }

      return strFile;
    },
    [shipment, canManageCarrierData, canManageShipperData, dispatch]
  );

  const handleUploadFile = useCallback(
    async (
      documentToUpload: TShipmentDocumentToUploadV1,
      files: FileList,
      otherFormData?: Record<string, any>
    ): Promise<void> => {
      try {
        setIsUploadingPerTypeState((prev) => {
          if (!prev[documentToUpload.documentType]) {
            return {
              ...prev,
              [documentToUpload.documentType]: true,
            };
          }
          return prev;
        });

        const documentMetadata: TOnDocumentUploadSuccessInput = getDocumentMetadataForUpload(
          shipment.shipment.shipmentRef,
          files,
          documentToUpload
        );

        // A -- Physical upload to S3
        await uploadFileToBucket(
          documentMetadata.documentLocation,
          files[0],
          SHIPMENT_DOCUMENT_UPLOAD,
          'application/pdf'
        );

        // B -- Logical actions after successfully getting the doc to S3
        await onDocumentUploadSuccess(documentMetadata, otherFormData);
        if (documentToUpload.documentType === EShipmentDocumentTypeV1.PROOF_OF_DELIVERY) {
          addAlert({ message: t('common:uploadSuccessUploadInvoice'), severity: 'success' });
        } else {
          addAlert({ message: t('common:uploadSuccess'), severity: 'success' });
        }
        handleListRefresh();
        //
      } catch (err) {
        errorHandler(err, t('common:uploadFailed'));
        try {
          onDocumentUploadFailure(documentToUpload.documentType);
        } catch (err) {
          errorHandler(err, t('common:uploadFailed'));
        }
      }
    },
    [
      getDocumentMetadataForUpload,
      shipment?.shipment?.shipmentRef,
      handleListRefresh,
      onDocumentUploadSuccess,
      addAlert,
      errorHandler,
      t,
      onDocumentUploadFailure,
    ]
  );

  const handleCommentAttachments = useCallback(
    async (files: File[]): Promise<TShipmentCommentAttachment[]> => {
      return await uploadCommentAttachments(shipmentRef, SHIPMENT_DOCUMENT_UPLOAD, files);
    },
    [shipmentRef]
  );

  const handleAddComment = useCallback(
    async (request: TAddCommentPayloadV1) => {
      const { attachments } = request;
      // IF there are any attachments, we need to upload them
      // and gather information about the files
      let attachmentData: TShipmentCommentAttachment[] = [];
      if (attachments) {
        attachmentData = await handleCommentAttachments(attachments);
      }

      const _tempId = new Date().toISOString();
      const commentData = { ...request, _tempId, attachments: attachmentData };
      dispatch(addTempShipmentComment(commentData));
      const comment = await addComment(commentData);
      dispatch(postCommentAdd({ ...commentData, comment }));
    },
    [dispatch, handleCommentAttachments]
  );

  const [isUploadingPerTypeState, setIsUploadingPerTypeState] = useState(
    (): Record<EShipmentDocumentTypeV1, boolean> => {
      const initialState = {};
      Object.values(EShipmentDocumentTypeV1).forEach((documentType: EShipmentDocumentTypeV1) => {
        initialState[documentType] = false;
      });
      return initialState as Record<EShipmentDocumentTypeV1, boolean>;
    }
  );

  const renderCarrierNameOrLinkToProfileIfExists = useCallback(() => {
    return carrierData?.carrierProfileRecord?.length > 0 && carrierData?.company ? (
      <PageLink
        pageId={'carrier/[carrier]'}
        routeParams={[carrierData?.company.code]}
        shouldUseModernVariant
      >
        {carrierData?.company.name}
      </PageLink>
    ) : null;
  }, [carrierData]);

  const carrierDetails: [TCompanyV1 | undefined | null, () => JSX.Element | null] =
    carrierCompanyCode
      ? [carrierData?.company, renderCarrierNameOrLinkToProfileIfExists]
      : [undefined, null];

  const emptyCommentDisplay = (
    <TruxiiMessage
      additionalContent={
        <Box mt={1.5} style={{ width: '100%' }}>
          <Typography>
            {t('common:chatWithYourContent', {
              userType: t(`common:${otherUserType[0].toLowerCase()}`),
            })}{' '}
          </Typography>
        </Box>
      }
      content={t('common:chatWithYour', {
        userType: t(`common:${otherUserType[0].toLowerCase()}`),
      })}
      direction="column"
    />
  );

  const promptForCarrierBankInformation = (
    <Grid container direction="column">
      <Grid item>
        <Typography>
          <Trans i18nKey={'common:bankAccountDetailsMissingBeforePODprompt'}>
            &nbsp;
            <PageLink
              className={classes.linkOrButton}
              pageId={'account/payment'}
              target="account/payment"
            >
              <Typography color="primaryLight">{t('common:placeholder')}</Typography>
            </PageLink>
          </Trans>
        </Typography>
      </Grid>
      <Grid item>
        <Box mb={1} mt={1}>
          <Typography>
            <Trans i18nKey={'common:refreshBankingDetailStatus'}>
              &nbsp;
              <button
                className={classes.linkOrButton}
                onClick={refreshShipmentDetails}
                type="button"
              >
                <Typography color="primaryLight">{t('common:placeholder')}</Typography>
              </button>
            </Trans>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  useInterval(handleRefresh, QUOTE_SIDEBAR_REFRESH_MS);

  useEffect(() => {
    if (hasShipmentLoaded && !shipment) {
      setInError(true);
      setHasSidebarLoaded(true);

      addAlert({
        message: t('common:errorLoadingShipment', { shipmentRef }),
        severity: 'error',
      });

      handleClose();
    } else if (hasShipmentLoaded) {
      setLocations(getOriginAndDestinationFromShipmentLocations(shipment?.location || []));

      if (
        haveDocumentTypesLoaded &&
        haveShipperPermissionsEvaluated &&
        haveCarrierPermissionsEvaluated &&
        haveSearchParamsLoaded
      ) {
        setHasSidebarLoaded(true);
      }
    }
  }, [
    t,
    shipmentRef,
    addAlert,
    setInError,
    shipment,
    setLocations,
    hasShipmentLoaded,
    setHasSidebarLoaded,
    handleClose,
    haveDocumentTypesLoaded,
    haveShipperPermissionsEvaluated,
    haveCarrierPermissionsEvaluated,
    haveSearchParamsLoaded,
  ]);

  useEffect(() => {
    setSidebarSections((value) => {
      return { ...value, [defaultExpandedSection]: true };
    });
  }, [setSidebarSections, defaultExpandedSection]);

  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
    }
  }, [setShouldRefetch, shouldRefetch]);

  if (isInError) {
    return <></>;
  }

  return (
    <>
      <Dialog onClose={handleCloseSubscription} open={isSubscriptionDialogShown}>
        <DialogContent style={{ maxWidth: 468, position: 'relative' }}>
          <Box mt={-4} pb={3} pl={2} pr={3}>
            <Grid alignItems="center" container direction="column" justifyContent="center">
              <SubscriptionMarketing onSuccess={handleSubscriptionSuccess} />
              <Box mt={3}>
                <FlatButton
                  color="primaryLight"
                  onClick={handleCloseSubscription}
                  variant="contained"
                >
                  {t('common:close')}
                </FlatButton>
              </Box>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {hasShipmentLoaded && canShareShipment && (
        <div style={{ left: 24, position: 'absolute', right: 96, top: 24 }}>
          <ShipmentShareLink
            addAlert={addAlert}
            getSharedLink={async () => {
              return await getShipmentShareLink(userType, shipmentRef);
            }}
            isShared={shipment?.shipment?.metadata?.sharing?.[userType] || false}
            setSharedState={async (state) => {
              const results = await updateShipmentShareState(userType, shipmentRef, state);
              handleRefresh();
              return results;
            }}
            shareLinkPrefix={shareLinkPrefix}
            t={t}
          />
        </div>
      )}
      <ShipmentsDashboardSidebar<void>
        accessorials={
          !haveSearchParamsLoaded ? [] : Object.values(searchFormParams?.accessorials || {})
        }
        addAlert={addAlert}
        addComment={handleAddComment}
        availableCarrierTags={userType === EUserTypeV1.CARRIER ? tags?.data || [] : []}
        availableShipperTags={userType === EUserTypeV1.SHIPPER ? tags?.data || [] : []}
        canAdministerShipments={false}
        canCarrierAddAdditionalCharges={CAN_CARRIER_ADD_ADDITIONAL_CHARGES}
        canManageCarrierData={canManageCarrierData}
        canManageShipperData={canManageShipperData}
        canManageTags={true}
        canSearchFromShipment
        canSetTaxExemptStatus={canSetTaxExemptState}
        carrierDetails={carrierDetails}
        commentUserData={shipment?.commentUserData as any}
        companyType={userType}
        emptyCommentDisplay={emptyCommentDisplay}
        googleApiKey={GOOGLE_MAPS_API_KEY}
        handleDownloadDocument={handleDownloadDocument}
        handleRefresh={handleRefresh}
        handleRouteToPage={(pageId) => {
          if (pageId === 'feeExplanation-TRUXWEB') {
            onRouteToSubscription();
          } else {
            console.warn(`${pageId} no supported yet`);
          }
        }}
        handleSearch={async () => {
          const searchData = await shipmentToSearch({ searchParams: searchFormParams, shipment });
          const loadDefinition = shipmentToLoadDefinition({
            searchParams: searchFormParams,
            shipment,
          });
          searchData.loadDefinition = loadDefinition;

          const query = convertFormDataToBookingQueryV1(
            searchData as TQuoteSearchFormDataV1,
            searchFormParams,
            Boolean(shipmentCredits.data.length)
          );

          saveBookingQuery(query);
          dispatch(setSearchFormData({ data: searchData, shouldForceRefresh: false }));
          dispatch(clearSearchResults());
          dispatch(setLoad({ data: { ...loadDefinition, isExistingSearch: false } }));

          const resultsRoute = getLocalizedRoute(router.locale, 'results');
          router.push(resultsRoute);
        }}
        handleSidebarSections={handleSidebarSections}
        handleUploadFile={handleUploadFile}
        hasLoaded={hasSidebarLoaded}
        isLoading={isLoading || isShipmentLoading || !havePermissionsEvaluated}
        isUploadingPerTypeState={isUploadingPerTypeState}
        language={language}
        locations={locations}
        markCommentsAsRead={markCommentsAsRead}
        onEditShipment={onEditShipment}
        onEditShipmentLocation={onEditShipmentLocation}
        promptForCarrierBankInformation={promptForCarrierBankInformation}
        renderQuoteLink={renderQuoteLink}
        searchFormParams={searchFormParams}
        shipment={shipment}
        shipmentComments={comments}
        shipmentDocumentTypes={shipmentDocumentTypes}
        sidebarSectionStates={sidebarSections}
        t={t}
      />
    </>
  );
};
