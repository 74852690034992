"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentsCSPDataGrid = void 0;
const schemas_1 = require("@truxweb/schemas");
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const tableColumns_1 = require("../../tableColumns");
const utils_1 = require("@truxweb/utils");
const __1 = require("..");
const ShipmentsCSPDataGrid_styles_1 = require("./ShipmentsCSPDataGrid.styles");
const DEFAULT_ROW_HEIGHT = 96;
const ShipmentsCSPDataGrid = (props) => {
    const { accessorial, availableCarrierTags, availableShipperTags, availableTruxwebTags, equipment, gapInterval, handleRouteToShipment, language, renderCompanyLink, rowHeight, t } = props, others = __rest(props, ["accessorial", "availableCarrierTags", "availableShipperTags", "availableTruxwebTags", "equipment", "gapInterval", "handleRouteToShipment", "language", "renderCompanyLink", "rowHeight", "t"]);
    const classes = (0, ShipmentsCSPDataGrid_styles_1.useStyles)();
    const fallbackText = t('common:na');
    const theme = (0, ux_1.useTheme)();
    const heightAdj = gapInterval ? theme.spacing(gapInterval) : 0;
    const tableRowHeight = rowHeight || DEFAULT_ROW_HEIGHT;
    const [availableColumns] = (0, react_1.useMemo)(() => {
        const basicColumnDef = {
            classes,
            fallbackText,
            t,
        };
        const availableColumns = [
            (0, tableColumns_1.shipmentComboShipmentRefColumn)(Object.assign(Object.assign({}, basicColumnDef), { canViewCarrierReferenceNumber: true, canViewShipperReferenceNumber: true, heightAdj, rowHeight: tableRowHeight, viewingUserType: schemas_1.EUserTypeV1.TRUXWEB })),
            (0, tableColumns_1.shipmentInternalStatusTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { valueOptions: Object.keys(schemas_1.EShipmentStatusV1).map((status) => t(`shipments:shipmentStatus-${status}`)) })),
            (0, tableColumns_1.shipmentCarrierStatusTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'carrierDisplayStatus', headerName: t('common:carrierStatus'), valueOptions: Object.keys(schemas_1.EShipmentDisplayStatusV1).map((status) => t(`shipments:shipmentStatus-${status}`)) })),
            (0, tableColumns_1.shipmentShipperStatusTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'shipperDisplayStatus', headerName: t('common:shipperStatus'), valueOptions: Object.keys(schemas_1.EShipmentDisplayStatusV1).map((status) => t(`shipments:shipmentStatus-${status}`)) })),
            (0, tableColumns_1.shipmentDelayedColumn)(Object.assign({}, basicColumnDef)),
            (0, tableColumns_1.shipmentActivityColumn)(Object.assign(Object.assign({}, basicColumnDef), { handleRouteToShipment })),
            (0, tableColumns_1.shipmentShipperNameColumn)(Object.assign(Object.assign({}, basicColumnDef), { renderLink: (rowData) => renderCompanyLink(schemas_1.EUserTypeV1.SHIPPER, rowData) })),
            (0, tableColumns_1.shipmentCarrierNameColumn)(Object.assign(Object.assign({}, basicColumnDef), { renderLink: (rowData) => renderCompanyLink(schemas_1.EUserTypeV1.CARRIER, rowData) })),
            (0, tableColumns_1.shipmentStaticStaticModifiedColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'price', headerName: t('common:price'), type: 'number', valueGetter: ({ row }) => {
                    return row.priceValue;
                }, width: 100 })),
            (0, tableColumns_1.shipmentStaticStaticModifiedColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'shipperSubtotal', headerName: t('common:shipperSubtotal'), type: 'number', valueGetter: ({ row }) => {
                    return row.shipperSubtotalValue;
                }, width: 100 })),
            (0, tableColumns_1.shipmentStaticStaticModifiedColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'carrierSubtotal', headerName: t('common:carrierSubtotal'), type: 'number', valueGetter: ({ row }) => {
                    return row.carrierSubtotalValue;
                }, width: 100 })),
            (0, tableColumns_1.shipmentStaticStaticModifiedColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'truxwebSubtotal', headerName: t('common:truxwebSubtotal'), type: 'number', valueGetter: ({ row }) => {
                    return row.truxwebSubtotalValue;
                }, width: 100 })),
            (0, tableColumns_1.shipmentStaticStaticModifiedColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'carrierTotalPaid', headerName: t('shipments:carrierTotalPaid'), type: 'number', width: 100 })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'carrierLastTransactionExtId', headerName: t('common:batchId'), width: 200 })),
            (0, tableColumns_1.shipmentPickUpActualDateColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentPickUpDateColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentDeliveryDateColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'paymentMethod', getTextValue: (row) => {
                    return t(`common:paymentMethod-${row.paymentMethod}`);
                }, headerName: t('shipments:paymentMethod'), width: 160 })),
            (0, tableColumns_1.shipmentShipperReferenceColumn)(Object.assign(Object.assign({}, basicColumnDef), { headerName: t('common:shipperReferenceNumber') })),
            (0, tableColumns_1.shipmentCarrierReferenceColumn)(Object.assign(Object.assign({}, basicColumnDef), { headerName: t('common:carrierReferenceNumber') })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'carrierQuoteReferenceNumber', headerName: t(`common:carrierQuoteRef`), width: 150 })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'shipperQuoteReferenceNumber', headerName: t('common:shipperQuoteRef'), width: 150 })),
            (0, tableColumns_1.shipmentTypeColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentPalletType)(basicColumnDef),
            (0, tableColumns_1.shipmentOriginColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentOriginReferenceColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentLocationContactColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'originContact', headerName: t('common:locationOriginContact'), location: schemas_1.EShipmentLocationTypeV1.ORIGIN })),
            (0, tableColumns_1.shipmentDestinationColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentLocationContactColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'destinationContact', headerName: t('common:locationDestinationContact'), location: schemas_1.EShipmentLocationTypeV1.DESTINATION })),
            (0, tableColumns_1.shipmentDestinationReferenceColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentEquipmentColumn)(Object.assign(Object.assign({}, basicColumnDef), { type: 'singleSelect', valueOptions: equipment.map(({ code }) => t(`common:SHIPPER-equipment-${code}`)) })),
            (0, tableColumns_1.shipmentAccessorialsColumn)(Object.assign(Object.assign({}, basicColumnDef), { type: 'singleSelect', valueOptions: accessorial.map(({ code }) => {
                    return t(`common:SHIPPER-accessorial-${code}`);
                }) })),
            (0, tableColumns_1.shipmentDisputeColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentTextDateColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'carrierPaymentDate', headerName: t('common:settlementDate'), valueGetter: ({ row }) => row.carrierPaymentDate || fallbackText, width: 250 })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'daysUntilCarrierPayment', headerName: t('shipments:daysUntilCarrierPayment'), sortable: true, tooltip: (row) => {
                    return row.carrierPaymentDate ? (0, utils_1.formatDate)(row.carrierPaymentDate) : fallbackText;
                }, valueGetter: (row) => {
                    var _a;
                    return ((_a = row.carrierPaymentDate) === null || _a === void 0 ? void 0 : _a.getTime()) || fallbackText;
                }, width: 150 })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'daysUntilShipperPayment', headerName: t('shipments:daysUntilShipperPayment'), sortable: true, tooltip: (row) => {
                    return row.shipperPaymentDate ? (0, utils_1.formatDate)(row.shipperPaymentDate) : fallbackText;
                }, valueGetter: (row) => {
                    var _a;
                    return ((_a = row.shipperPaymentDate) === null || _a === void 0 ? void 0 : _a.getTime()) || fallbackText;
                }, width: 150 })),
            (0, tableColumns_1.shipmentTextDateColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'readyForProcessingDate', headerName: t('shipments:readyForProcessingDate'), width: 150 })),
            (0, tableColumns_1.shipmentBookedDateColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentDateUpdatedColumn)(basicColumnDef),
            (0, tableColumns_1.tagsColumn)(Object.assign(Object.assign({}, basicColumnDef), { availableTags: availableShipperTags, field: 'shipperTags', headerName: t('common:shipperTags'), width: 350 })),
            (0, tableColumns_1.tagsColumn)(Object.assign(Object.assign({}, basicColumnDef), { availableTags: availableCarrierTags, field: 'carrierTags', headerName: t('common:carrierTags'), width: 350 })),
            (0, tableColumns_1.tagsColumn)(Object.assign(Object.assign({}, basicColumnDef), { availableTags: availableTruxwebTags, field: 'truxwebTags', headerName: t('common:truxwebTags'), width: 350 })),
        ];
        return [availableColumns];
    }, [
        accessorial,
        availableCarrierTags,
        availableShipperTags,
        availableTruxwebTags,
        classes,
        equipment,
        fallbackText,
        t,
        handleRouteToShipment,
        heightAdj,
        renderCompanyLink,
        tableRowHeight,
    ]);
    return (react_1.default.createElement(__1.GeneralCSPDataGrid, Object.assign({}, others, { availableColumns: availableColumns, handleCellClick: ({ field, row }) => {
            if (!['action', 'shipperName', 'carrierName'].includes(field)) {
                handleRouteToShipment(row.shipmentRef);
            }
        }, language: language, t: t })));
};
exports.ShipmentsCSPDataGrid = ShipmentsCSPDataGrid;
