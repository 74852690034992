import { useDispatch, useSelector } from 'react-redux';
import { createSearchRequest } from '../../../actions';
import { selectSearchRequestId } from '../../../selectors';
import { type TBookingSearchRequestV1 } from '@truxweb/schemas';
import { useEffect } from 'react';

export const useSearchRequestId = (query: TBookingSearchRequestV1 | null, isReady: boolean) => {
  const dispatch = useDispatch();
  const searchRequestId = useSelector(selectSearchRequestId());

  useEffect(() => {
    if (isReady && !searchRequestId.hasLoaded && !searchRequestId.isLoading && query !== null) {
      dispatch(createSearchRequest(query));
    }
  }, [searchRequestId, dispatch, query, isReady]);

  return searchRequestId;
};
