"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchResults = void 0;
const ux_1 = require("@truxweb/ux");
const utils_1 = require("@truxweb/utils");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const react_hook_form_1 = require("react-hook-form");
const date_fns_1 = require("date-fns");
const SearchResults_styles_1 = require("./SearchResults.styles");
const SearchResults = ({ addAlert, areExtendedSearchParamsOpen, carrierSidebarProfile, companySubscription, customCompleteValues, emptyGridDisplay, existingFormData, getRecentSeachesByLocationType, googleMapsApiKey, handleCloseCarrierProfile, handleNavigateToReservation, handleNavigateToSearch, haveResultsLoaded, haveSearchParamsLoaded, isAdminView, isBookingDisabled, isLoading, language, minDate, onSubmitSuccess, quoteMaxSelection, renderCarrierName, renderCompanyProfileLink, searchParams, searchQuery, searchResults, searchResultsError, selections, setSelections, shipmentCredits, shouldAllowPartialSearchInput, shouldSuppressProfileLink, t, trackingAccessorialId, userData, }) => {
    var _a;
    const [errorState, setErrorState] = (0, react_1.useState)(false);
    const [isSubmitDisabled, setSubmitDisabled] = (0, react_1.useState)(true);
    const [isResultsRefreshRequired, setResultsRefreshRequired] = (0, react_1.useState)(false);
    const [haveAccessorialsRestored, setAccessorialsRestored] = (0, react_1.useState)(false);
    const classes = (0, SearchResults_styles_1.useStyles)();
    const getEquipmentAccessorials = (0, react_1.useCallback)((equipmentCode) => {
        if (!equipmentCode)
            return [];
        return searchParams === null || searchParams === void 0 ? void 0 : searchParams.shipmentFeaturesEquipmentAccessorials[equipmentCode].map((code) => {
            return ((searchParams === null || searchParams === void 0 ? void 0 : searchParams.accessorials) || {})[code];
        });
    }, [searchParams]);
    const searchParamsFormRef = (0, react_1.useRef)();
    const { clearErrors, control, formState: { errors }, getValues, handleSubmit, setError, setValue, watch, } = (0, react_hook_form_1.useForm)({
        defaultValues: existingFormData || {},
    });
    const accessorialFormControls = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'accessorials',
    });
    // We need to reset this to process a forced refresh
    const processForceRefreshFlag = (0, react_1.useCallback)((data) => {
        if (!isResultsRefreshRequired)
            return;
        const filteredExistingAccessorialMetadata = (existingFormData.accessorialMetadata || []).map((record) => {
            return Object.assign(Object.assign({}, record), { value: record.metadataValue });
        });
        const existingForm = Object.assign(Object.assign({}, existingFormData), { accessorialMetadata: filteredExistingAccessorialMetadata });
        if (JSON.stringify(data) !== JSON.stringify(existingForm)) {
            setResultsRefreshRequired(true);
        }
    }, [existingFormData, isResultsRefreshRequired]);
    const [availableAccessorials, setAvailableAccessorials] = (0, react_1.useState)(getEquipmentAccessorials((_a = existingFormData === null || existingFormData === void 0 ? void 0 : existingFormData.equipment) === null || _a === void 0 ? void 0 : _a.code));
    const handleWatch = (0, react_1.useCallback)((data, { name }) => {
        if (isLoading)
            return;
        if (!haveAccessorialsRestored)
            return;
        if (isSubmitDisabled)
            setSubmitDisabled(false);
        if (data && haveAccessorialsRestored) {
            processForceRefreshFlag(data);
        }
        if (name === 'equipment') {
            setAvailableAccessorials(getEquipmentAccessorials(data.equipment.code));
        }
    }, [
        getEquipmentAccessorials,
        haveAccessorialsRestored,
        isLoading,
        setSubmitDisabled,
        processForceRefreshFlag,
        setAvailableAccessorials,
        isSubmitDisabled,
    ]);
    // NOTE: This watch function gets called _EXCESSIVELY_ this is why ther are so many
    // guards around `triggerProcessRefreshFlag`. Ideally, this would only be called _ONCE_
    // whenever each of the form values is changed, but that is not the case
    watch(handleWatch);
    const bookingDate = (searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.shipmentDates[0]) || new Date().toISOString();
    const handleSelectedBooking = (0, react_1.useCallback)((selected) => {
        setSelections(selected);
    }, [setSelections]);
    const [data] = (0, react_1.useMemo)(() => {
        const data = !(searchResults === null || searchResults === void 0 ? void 0 : searchResults.results)
            ? []
            : searchResults.results.map((results) => {
                const { accessorialIds, carrierCompanyCode, carrierExtId, carrierId, carrierName, carrierRating, dateUpdated, equipmentId, estimatedTransitTime, hasActiveProfile, hasExternalApiIntegration, price, rateSheetId, serviceType, serviceTypeId, } = results;
                const estimatedDeliveryTimeInDays = estimatedTransitTime <= 1
                    ? `${estimatedTransitTime} ${t('common:day')}`
                    : `${estimatedTransitTime} ${t('common:days')}`;
                const estimatedArrivalDate = (0, utils_1.formatLocalizedDate)((0, date_fns_1.add)(new Date(bookingDate), { days: estimatedTransitTime }), language, utils_1.DATE_FORMAT_STRINGS_SHORT[language]);
                const dateUpdateDate = dateUpdated.at(-1) === 'Z' ? new Date(dateUpdated) : new Date(`${dateUpdated}Z`);
                const formattedDateUpdated = (0, utils_1.formatLocalizedDate)(dateUpdateDate, language, utils_1.DATE_FORMAT_STRINGS_WITH_TIMES[language]);
                return {
                    accessorialIds,
                    carrierCompanyCode,
                    carrierId,
                    carrierName,
                    carrierRating,
                    dateUpdated: formattedDateUpdated,
                    equipmentId,
                    estimatedArrivalDate,
                    estimatedDeliveryTime: estimatedDeliveryTimeInDays,
                    hasActiveProfile,
                    hasExternalApiIntegration,
                    id: carrierExtId,
                    price,
                    rateSheetId,
                    serviceType,
                    serviceTypeId,
                };
            });
        return [data];
    }, [bookingDate, language, searchResults, t]);
    const handleSubmitSearchRequest = (0, react_1.useCallback)((data) => {
        setSubmitDisabled(true);
        setSelections([]);
        onSubmitSuccess(data);
        setResultsRefreshRequired(false);
    }, [setSelections, onSubmitSuccess, setSubmitDisabled, setResultsRefreshRequired]);
    const areFormValuesRequired = typeof shouldAllowPartialSearchInput === 'undefined' ? true : !shouldAllowPartialSearchInput;
    // NOTE: On the results page the accessorials are not being properly
    // restored from `existingFormData`, so we need to manually restore them
    (0, react_1.useEffect)(() => {
        if (haveResultsLoaded && existingFormData && !haveAccessorialsRestored) {
            setAccessorialsRestored(true);
        }
    }, [existingFormData, haveResultsLoaded, haveAccessorialsRestored, setAccessorialsRestored]);
    (0, react_1.useEffect)(() => {
        if (searchResultsError !== null && !errorState) {
            setErrorState(true);
            addAlert({ message: searchResultsError === null || searchResultsError === void 0 ? void 0 : searchResultsError.message, severity: 'error' });
        }
        if (haveSearchParamsLoaded && (availableAccessorials === null || availableAccessorials === void 0 ? void 0 : availableAccessorials.length) === 0 && searchQuery) {
            const equipmentRecord = Object.values(searchParams.equipment).find((equipment) => {
                return equipment.id === (searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.equipmentId);
            });
            setAvailableAccessorials(getEquipmentAccessorials(equipmentRecord.code));
        }
    }, [
        searchParams,
        getEquipmentAccessorials,
        searchQuery,
        searchResultsError,
        addAlert,
        errorState,
        setErrorState,
        setAvailableAccessorials,
        haveSearchParamsLoaded,
        availableAccessorials,
    ]);
    return (react_1.default.createElement(ux_1.Box, null,
        react_1.default.createElement(ux_1.Button, { className: classes.searchButton, color: "primary", "data-testid": `SearchResults-NewSearch`, onClick: () => {
                handleNavigateToSearch(true, true);
            }, variant: "text" }, t(`search:newSearch`)),
        react_1.default.createElement(ux_1.Grid, { className: classes.container, container: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", style: { maxWidth: '1080px' } },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(__1.SearchStepper, { currentStep: schemas_1.ESearchQuoteFormStepsV1.results, t: t })),
                    react_1.default.createElement(ux_1.Grid, { item: true }, haveSearchParamsLoaded && (react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { className: classes.searchBox },
                            react_1.default.createElement("form", { id: 'search-params-form', onSubmit: handleSubmit(handleSubmitSearchRequest), ref: searchParamsFormRef },
                                react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(__1.SearchParamsHeader, { accessorialFormControls: accessorialFormControls, accessorials: (searchParams === null || searchParams === void 0 ? void 0 : searchParams.accessorials) || undefined, addAlert: addAlert, areExtendedSearchParamsOpen: areExtendedSearchParamsOpen, areFormValuesRequired: areFormValuesRequired, availableAccessorials: availableAccessorials, clearErrors: clearErrors, control: control, customCompleteValues: customCompleteValues, enabledFields: [
                                                schemas_1.ESearchParamsV1.shipmentDate,
                                                schemas_1.ESearchParamsV1.origin,
                                                schemas_1.ESearchParamsV1.destination,
                                                schemas_1.ESearchParamsV1.equipment,
                                                schemas_1.ESearchParamsV1.accessorials,
                                            ], equipment: (searchParams === null || searchParams === void 0 ? void 0 : searchParams.equipment) || undefined, errors: errors, estimatedDeliveryDate: (data === null || data === void 0 ? void 0 : data.length) ? (data[0].estimatedArrivalDate) : !isLoading ? (t('common:na')) : (react_1.default.createElement(ux_1.CircularProgress, { size: 16 })), formParams: searchParams, getRecentSeachesByLocationType: getRecentSeachesByLocationType, getValues: getValues, googleMapsApiKey: googleMapsApiKey, hasExtendedSearchOptions: true, isLoading: isLoading, language: language, minDate: minDate, pageId: 'results', setError: setError, setValue: setValue, t: t, userData: userData, values: getValues(), watch: watch })))))))),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(__1.SearchSummary, { language: language, onClick: (date) => {
                                setValue('shipmentDate', date);
                                handleSubmit(onSubmitSuccess)();
                            }, searchDate: (existingFormData === null || existingFormData === void 0 ? void 0 : existingFormData.shipmentDate) || new Date().toISOString(), summary: (searchResults === null || searchResults === void 0 ? void 0 : searchResults.summary) || [] })),
                    Boolean(companySubscription) &&
                        (companySubscription === null || companySubscription === void 0 ? void 0 : companySubscription.isActive) &&
                        Boolean(companySubscription === null || companySubscription === void 0 ? void 0 : companySubscription.nextRenewalDate) &&
                        shipmentCredits.length === 0 && (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "column", item: true, justifyContent: "center" },
                        react_1.default.createElement(ux_1.Box, { mt: 3 },
                            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:subscriptionRenewal', {
                                renewalDate: (0, utils_1.formatLocalizedDate)(companySubscription === null || companySubscription === void 0 ? void 0 : companySubscription.nextRenewalDate, language, utils_1.DATE_FORMAT_STRINGS[language]),
                            }))))),
                    (!Boolean(companySubscription) || !(companySubscription === null || companySubscription === void 0 ? void 0 : companySubscription.isActive)) &&
                        shipmentCredits.length !== 0 &&
                        shipmentCredits.length < 100 && (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, justifyContent: "center" },
                        react_1.default.createElement(ux_1.Box, { mt: 3 },
                            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:youHaveCredits', { shipmentCredits: shipmentCredits.length }))))),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(__1.SearchResultsDataGrid, { addAlert: addAlert, bookingData: (searchResults === null || searchResults === void 0 ? void 0 : searchResults.results) || [], emptyGridDisplay: emptyGridDisplay, gridData: data, handleBooking: handleSelectedBooking, isAdminView: isAdminView, isLoading: isLoading, langauge: language, quoteMaxSelection: quoteMaxSelection, renderCarrierName: renderCarrierName, renderCompanyProfileLink: renderCompanyProfileLink, selections: selections, setSelections: setSelections, shouldSuppressProfileLink: shouldSuppressProfileLink, t: t, trackingAccessorialId: trackingAccessorialId })),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { ml: 6.5, mr: 6.5 },
                                react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", justifyContent: "space-between" },
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(ux_1.FlatButton, { color: 'primaryLight', "data-testid": `SearchResults-BackButton`, onClick: () => {
                                                handleNavigateToSearch(true);
                                            }, variant: "outlined" }, t(`common:back`))),
                                    !isBookingDisabled && (react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(__1.SaveButton, { color: isResultsRefreshRequired ? 'primaryDark' : 'primaryLight', customAction: !isResultsRefreshRequired
                                                ? () => {
                                                    if (handleNavigateToReservation) {
                                                        handleNavigateToReservation(selections);
                                                    }
                                                }
                                                : undefined, formName: isResultsRefreshRequired ? 'search-params-form' : undefined, isDisabled: isResultsRefreshRequired ? false : isLoading || !selections.length, t: t, testId: `SearchResults` },
                                            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, spacing: 2 },
                                                isResultsRefreshRequired && (react_1.default.createElement(ux_1.Grid, { item: true, style: { marginTop: '4px' } },
                                                    react_1.default.createElement(ux_1.TruxwebSearchIcon, { style: { fill: '#FFF' } }))),
                                                react_1.default.createElement(ux_1.Grid, { item: true }, isResultsRefreshRequired ? t('search:search') : t('common:next'))))))))))))),
        react_1.default.createElement(ux_1.RightHandSidebar, { handleClose: handleCloseCarrierProfile, isOpen: Boolean(carrierSidebarProfile) }, carrierSidebarProfile !== undefined ? carrierSidebarProfile : react_1.default.createElement(react_1.default.Fragment, null))));
};
exports.SearchResults = SearchResults;
