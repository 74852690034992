import {
  baseLoadingState,
  manageDomainError,
  manageDomainLoaded,
  manageDomainLoading,
} from './stateUtils';
import {
  type TBookingSearchResponseV1,
  TLoadDefinitionV1,
  type TQuoteSearchFormDataV1,
  type TSearchParamsData,
} from '@truxweb/schemas';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TReducerLoading } from '../types';

export type TSearchFormParams = TReducerLoading & {
  data: TSearchParamsData;
};

export type TSavedSearchData = TReducerLoading & {
  data: TQuoteSearchFormDataV1 | null;
  shouldForceRefresh: boolean;
};
export type TSearchResults = TReducerLoading & {
  data: TBookingSearchResponseV1;
};

export type THasRequestedServiceOnEmptyResults = TReducerLoading & {
  data: boolean;
};

export type TSearchRequestId = TReducerLoading & {
  data: number;
};

export interface ISearchDataState {
  searchFormParams: TSearchFormParams;
  searchRequestId: TSearchRequestId;
  savedFormData: TSavedSearchData;
  searchResults: TSearchResults;
  hasRequestedServiceOnEmptyResults: THasRequestedServiceOnEmptyResults;
  searchLoadData:
    | (TLoadDefinitionV1 & {
        isExistingSearch: boolean;
      })
    | null;
}

export const searchSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.search,
      };
    },
  },
  initialState: {
    hasRequestedServiceOnEmptyResults: {},
    savedFormData: {},
    searchFormParams: {},
    searchLoadData: {},
    searchRequestId: {},
    searchResults: {},
  } as ISearchDataState,
  name: 'search',
  reducers: {
    clearLoad: (state) => {
      return {
        ...state,
        searchLoadData: null,
      };
    },
    clearSearchResults: (state) => {
      return {
        ...state,
        hasRequestedServiceOnEmptyResults: { ...baseLoadingState, data: false },
        searchRequestId: { ...baseLoadingState, data: null, hasLoaded: false },
        searchResults: { ...baseLoadingState, data: {}, hasLoaded: false },
      };
    },
    setHasRequestedServiceOnEmptyResultsData: (state, { payload: { data } }) => {
      return {
        ...state,
        hasRequestedServiceOnEmptyResults: manageDomainLoaded(
          state.hasRequestedServiceOnEmptyResults,
          data
        ),
      };
    },
    setHasRequestedServiceOnEmptyResultsError: (state, { payload: { error } }) => {
      return {
        ...state,
        hasRequestedServiceOnEmptyResults: manageDomainError(
          state.hasRequestedServiceOnEmptyResults,
          error
        ),
      };
    },
    setHasRequestedServiceOnEmptyResultsLoading: (state) => {
      return {
        ...state,
        hasRequestedServiceOnEmptyResults: manageDomainLoading(
          state.hasRequestedServiceOnEmptyResults
        ),
      };
    },
    setLoad: (state, { payload: { data } }) => {
      return {
        ...state,
        searchLoadData: data,
      };
    },
    setSearchFormData: (state, { payload: { data, shouldForceRefresh } }) => {
      return {
        ...state,
        savedFormData: {
          ...manageDomainLoaded(state.savedFormData, data),
          shouldForceRefresh,
        },
      };
    },
    setSearchFormParamsData: (state, { payload: { data } }) => {
      // If the params are being set to null, we need to restore the form params to their base state
      if (data === null) {
        return { ...state, searchFormParams: { ...baseLoadingState, data } };
      }
      return { ...state, searchFormParams: manageDomainLoaded(state.searchFormParams, data) };
    },
    setSearchFormParamsError: (state, { payload: { error } }) => {
      return { ...state, searchFormParams: manageDomainError(state.searchFormParams, error) };
    },
    setSearchFormParamsLoading: (state) => {
      return { ...state, searchFormParams: manageDomainLoading(state.searchFormParams) };
    },
    setSearchRequestIdError: (state, { payload: { error } }) => {
      return {
        ...state,
        searchRequestId: manageDomainError(state.searchRequestId, error),
      };
    },
    setSearchRequestIdLoaded: (state, { payload: { messageId } }) => {
      return {
        ...state,
        searchRequestId: manageDomainLoaded(state.searchRequestId, messageId),
      };
    },
    setSearchRequestIdLoading: (state) => {
      return {
        ...state,
        searchRequestId: manageDomainLoading(state.searchRequestId),
      };
    },
    setSearchResultsData: (state, { payload: { data } }) => {
      return {
        ...state,
        hasRequestedServiceOnEmptyResults: { ...baseLoadingState, data: false },
        searchResults: manageDomainLoaded(state.searchResults, data),
      };
    },
    setSearchResultsError: (state, { payload: { error } }) => {
      return { ...state, searchResults: manageDomainError(state.searchResults, error) };
    },
    setSearchResultsLoading: (state) => {
      return { ...state, searchResults: manageDomainLoading(state.searchResults) };
    },
  },
});

export const {
  actions: {
    clearLoad,
    clearSearchResults,
    setHasRequestedServiceOnEmptyResultsData,
    setHasRequestedServiceOnEmptyResultsError,
    setHasRequestedServiceOnEmptyResultsLoading,
    setLoad,
    setSearchFormData,
    setSearchFormParamsData,
    setSearchFormParamsError,
    setSearchFormParamsLoading,
    setSearchRequestIdError,
    setSearchRequestIdLoaded,
    setSearchRequestIdLoading,
    setSearchResultsData,
    setSearchResultsError,
    setSearchResultsLoading,
  },
} = searchSlice;
