"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    button: {
        alignItems: 'center',
        background: theme.palette.error.light,
        border: 0,
        borderRadius: '100%',
        color: '#fff',
        cursor: 'pointer',
        display: 'flex',
        fontWeight: 800,
        height: 24,
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        width: 24,
    },
}));
