"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    cancelDelete: {
        color: theme.palette.common.white,
    },
    deleteButton: {
        minWidth: `${theme.spacing(5)}px`,
        width: `${theme.spacing(5)}px`,
    },
    deleteContainer: {
        background: theme.palette.error.dark,
        height: 96,
    },
    deleteWrapper: {
        bottom: 0,
        left: `-32px`,
        position: 'absolute',
        right: `-32px`,
        top: 0,
    },
}));
