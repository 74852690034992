"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipperAccessorialManagement = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const ShipperAccessorialManagement = ({ accessorialLookup, accessorialsControls, availableAccessorials, control, t, }) => {
    const onAdd = (0, react_1.useCallback)(() => {
        const accessorialToAdd = availableAccessorials[0];
        accessorialsControls.append(accessorialToAdd);
    }, [accessorialsControls, availableAccessorials]);
    const onRemove = (0, react_1.useCallback)((index) => {
        accessorialsControls.remove(index);
    }, [accessorialsControls]);
    const handlePickerChange = (0, react_1.useCallback)((event, index) => {
        accessorialsControls.update(index, accessorialLookup[event.target.value]);
    }, [accessorialLookup, accessorialsControls]);
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", spacing: 2 },
        accessorialsControls.fields.map((field, index) => {
            return (react_1.default.createElement(ux_1.Grid, { item: true, key: field.id },
                react_1.default.createElement(__1.AccessorialPickerCard, { accessorialFormControls: accessorialsControls, accessorials: accessorialLookup, control: control, deleteHandler: () => {
                        onRemove(index);
                    }, handlePickerChange: handlePickerChange, hasError: false, index: index, isCompactDisplay: true, prefix: `loadDefinition.accessorialSelections`, selectedAccessorial: field, shouldForceMetadataInput: true, t: t })));
        }),
        react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.StandardButton, { onClick: onAdd },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true },
                        react_1.default.createElement(ux_1.TruxwebAddIcon, null),
                        "\u00A0",
                        react_1.default.createElement(ux_1.Typography, { color: "primaryLight", style: { fontSize: 16 }, variant: "bodyMedium" }, t('search:addAccessorial'))))))));
};
exports.ShipperAccessorialManagement = ShipperAccessorialManagement;
